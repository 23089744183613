.petsolv-skin {
  --form-header-background-color-rgb: 60, 0, 38;
  --form-header-background-color-alpha: 0.65;
  --form-background-color-rgb: 200, 200, 200;
  --form-background-color-alpha: 0.85;
  --form-divider-color: #858585;

  --primary-button-text-color: #efefef;
  --primary-button-active-text-color: #fff; /*#000;*/
  --primary-button-background-color: #4a0d66; /*#8b3dff; #f5f375; *#f4546f;*/
  --primary-button-active-background-color: #621187; /*#8b3dff; /*#761bfd; /*#bdbb4e; #eae864; #d23f57;*/
  --primary-button-border-color: #621187; /*#761bfd; #c1bf4b; #f5f375; #f4546f;*/

  --button-text-color: #000;
  --button-active-text-color: #000;
  --button-background-color: #eee; /*#f4546f;*/
  --button-active-background-color: #bbb; /*#eae864; #d23f57;*/
  --button-border-color: #ccc; /*#f5f375; #f4546f;*/

  --pagedialog-header-background-color: #4a0d668f; /*#35083cad; /*#57093485; #5a00b380; #7b3abb80; #f4546f57;*/

  --widget-background-color: #e8e8e861;
  --widget-clickable-background-color: #ffffff61;
  --widget-selected-background-color: #f0f0f061;

  --main-background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url('https://sassets.petsolv.com/brand/petsolv-bg-reunited-588-compressed.png') center center/cover no-repeat
}


@media (prefers-color-scheme: dark) {
  .petsolv-skin {
    --form-background-color-rgb: 34, 34, 34;
    --form-control-background-color: #222;

    --button-text-color: #efefef;
    --button-active-text-color: #fff;
    --button-background-color: #555; /*#f4546f;*/
    --button-active-background-color: #888; /*#eae864; #d23f57;*/
    --button-border-color: #666; /*#f5f375; #f4546f;*/

    --pagedialog-background-color: #333333dd;
    --pagedialog-header-background-color: #35083cad;
    --pagedialog-footer-text-color: #aaa;

    --widget-background-color: #00000052;
    --widget-clickable-background-color: #78787852;
    --widget-selected-background-color: #28282852;
  }
}

.text-link {
  text-decoration-color: inherit;
}

.features {
  display: flex;
  justify-content: start;
  text-align: left;
  padding: 18px 20px 0 4px;
}

.features ul {
  list-style: none;
  /*padding: 0;*/
}

/*.features ul li:before {*/
/*  content: '✓ ';*/
/*}*/

.features ul li::marker {
  content: '✓';
  color: #bc29ff; /*var(--primary-button-background-color);*/
  font-weight: 900;
}

.features ul li {
  margin-left: -30px;
  padding-left: 6px;
  padding-bottom: 14px;
}

.pricing-plan-ribbon {
  background-color: #007bff; /*rgb(138 43 226);*/
}

.pricing-plan-recommended {
  background-color: rgb(123 58 187 / 0.2);
}

.petsolv-skin .adcreative-container {
  position: relative;
  box-sizing: border-box;
  font-family: "Arial", "sans-serif", "serif";
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  height: 250px;
  min-height: 250px;
  max-height: 250px;
  /*color: black;*/
  /*background-color: white;*/
  overflow: hidden;
  border:  4px solid red;
}

.petsolv-skin .adcreative-container .header {
  font-family: "Verdana", "sans-serif", "serif";
  position: absolute;
  top: 0px;
  left: 0;
  font-size: 22px;
  font-weight: 700;
  width: 292px;
  height: 34px;
  line-height: 30px;
  text-transform: uppercase;
  background-color: red;
  color: white;
  white-space: nowrap;
  transform: scaleY(1.1);
}

.petsolv-skin .adcreative-container .header input {
  font-family: "Verdana", "sans-serif", "serif";
  font-size: 22px;
  font-weight: 700;
  width: 292px;
  padding: 0;
  text-align: center;
  line-height: 30px;
  outline: 0;
  color: white;
  text-transform: uppercase;
  transform: scaleY(1.1);
  background-color: transparent;
  border: 0;
}

.petsolv-skin .adcreative-container .header input:hover {
  background-color: #ce0000;
  border: 0;
}

.petsolv-skin .adcreative-container .header input:focus {
  background-color: #ce0000;
  border: 0;
}

.petsolv-skin .adcreative-container .title {
  display: flex;
  align-items: center;
  position: absolute;
  top: 37px;
  left: 146px;
  width: 138px;
  height: 25px;
  font-family: "Verdana", "sans-serif", "serif";
  font-size: 18px;
  font-weight: 700;
  color: red;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
  transform: scaleY(1.1);
  border: 0;
}

.petsolv-skin .adcreative-container .title input {
  font-family: "Verdana", "sans-serif", "serif";
  font-size: 10pt;
  font-weight: normal;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  color: red;
  background-color: #fff;
  width: 138px;
  outline: 0;
  text-transform: uppercase;
  transform: scaleY(1.1);
  border: 0;
}

.petsolv-skin .adcreative-container .title input:focus {
  background-color: #eee;
  border: 0;
}

.petsolv-skin .adcreative-container .title input:hover {
  background-color: #eee;
  border: 0;
}

.petsolv-skin .adcreative-container .photo {
  position: absolute;
  top: 35px;
  left: 0px;
  width: 140px;
  height: 180px;
}

.petsolv-skin .adcreative-container .photo img {
  width: 140px;
  height: 180px;
  object-fit: contain;
}

.petsolv-skin .adcreative-container .content {
  position: absolute;
  top: 64px;
  left: 146px;
  width: 138px;
  min-width: 138px;
  max-width: 138px;
  height: 146px;
  min-height: 146px;
  max-height: 146px;
  font-family: "Verdana", "sans-serif", "serif";
  font-size: 10px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  /*background-color: white;*/
}

.petsolv-skin .adcreative-container .content textarea {
  font-family: "Verdana", "sans-serif", "serif";
  color: #000;
  background-color: #fff;
  font-size: 10px;
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  overflow-x: hidden;
  overflow-y: scroll;
}

.petsolv-skin .adcreative-container .content textarea:focus {
  background-color: #eee;
  /*border: 1px solid #ccc;*/
}

.petsolv-skin .adcreative-container .content textarea:hover {
  background-color: #eee;
  /*border: 0;*/
}

.petsolv-skin .adcreative-container .content ::-webkit-scrollbar {
  display: none;
}

.petsolv-skin .adcreative-container .content.no-tenant-info {
  top: calc(100px - 32px) !important;
  height: calc(120px + 32px) !important;
  max-height: calc(120px + 32px) !important;
}

.petsolv-skin .adcreative-container .content h1, h2, h3, h4, h5, h6 {
  font-weight: 700 !important;
  margin: 0 0 6px 0 !important;
}

.petsolv-skin .adcreative-container .content h1 {
  font-size: 18px;
}
.petsolv-skin .adcreative-container .content h2 {
  font-size: 16px;
}
.petsolv-skin .adcreative-container .content h3 {
  font-size: 14px;
}
.petsolv-skin .adcreative-container .content h4 {
  font-size: 12px;
}
.petsolv-skin .adcreative-container .content h5 {
  font-size: 10px;
}
.petsolv-skin .adcreative-container .content h6 {
  font-size: 10px;
}

.petsolv-skin .adcreative-container strike {
  color: red;
  text-decoration: none;
}

.petsolv-skin .adcreative-container .content p {
  padding: 0;
  margin: 0 0 8px 0;
}
.petsolv-skin .adcreative-container .content a {
  /*color: #58a6ff;*/
  text-decoration: none;
  border-bottom: 0;
}
.petsolv-skin .adcreative-container .content a:hover {
  text-decoration: underline;
  border-bottom: 0;
}

.petsolv-skin .adcreative-container .content table {
  width: 96%;
  margin-bottom: 16px;
  border: 0;
  border-top: 1px solid rgba(0,0,0,0.5);
  border-bottom: 1px solid rgba(0,0,0,0.5);
  border-spacing: 0;
}
.petsolv-skin .adcreative-container .content table th {
  border-left: 1px solid rgba(0,0,0,0.5);
  background: rgba(0,0,0,0.1);
  padding: 6px;
}
.petsolv-skin .adcreative-container .content table td {
  border-left: 1px solid rgba(0,0,0,0.5);
  border-top: 1px solid rgba(0,0,0,0.5);
  padding: 6px;
}
.petsolv-skin .adcreative-container .content table tbody tr:first-child td {
  border-left: 1px solid rgba(0,0,0,0.5);
  border-top: 1px solid rgba(0,0,0,0.5);
}
.petsolv-skin .adcreative-container .content table tbody tr td:last-child {
  border-right: 1px solid rgba(0,0,0,0.5);
}
.petsolv-skin .adcreative-container .content table tbody tr:nth-child(even) {
  background: rgba(0,0,0,0.1);
}

.petsolv-skin .adcreative-container .content ul {
  padding-inline-start: 14px;
}
.petsolv-skin .adcreative-container .content ul li {
  list-style: disc;
}
.petsolv-skin .adcreative-container .content ol {
  padding-inline-start: 14px;
}
.petsolv-skin .adcreative-container .content ol li {
  list-style: decimal;
}
.petsolv-skin .adcreative-container .content hr {
  border: 0;
  border-top: 1px solid #dfdfdf;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.petsolv-skin .adcreative-container .footer {
  position: absolute;
  top: 216px;
  left: 0px;
  width: 292px;
  max-width: 292px;
  min-width: 292px;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  font-family: "Verdana", "sans-serif", "serif";
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: white;
  padding: 4px 0 0 0;
  background-color: red;
  margin: 0;
  transform: scaleY(1.1);
}

.petsolv-skin .adcreative-container .footer input {
  font-family: "Verdana", "sans-serif", "serif";
  font-size: 16px;
  font-weight: 700;
  color: white;
  text-align: center;
  width: 292px;
  outline: 0;
  padding: 0;
  transform: scaleY(1.1);
  background-color: transparent;
  border: 0;
}

.petsolv-skin .adcreative-container .footer input:focus {
  background-color: #ce0000;
  border: 0;
}

.petsolv-skin .adcreative-container .footer input:hover {
  background-color: #ce0000;
  border: 0;
}

.petsolv-skin .adcreative-container .fields {
  height: 100%;
}

.petsolv-skin .adcreative-container .field-label {
  font-size: 10pt;
  color: red;
}

.petsolv-skin .adcreative-container .field-value {
  font-size: 10pt;
}

.petsolv-skin .adcreative-container .field-value input {
  font-size: 10pt;
  width: 100%;
}

.petsolv-skin .adcreative-container .field-row.field-style-lcell {
  display: table-row;
}
.petsolv-skin .adcreative-container .field-row.field-style-lcell .field-label {
  display: table-cell;
  text-align: left;
  padding-right: 4px;
  white-space: nowrap;
}
.petsolv-skin .adcreative-container .field-row.field-style-lcell .field-value {
  display: table-cell;
  padding-bottom: 1px;
}
.petsolv-skin .adcreative-container .field-row.field-style-rcell {
  display: table-row;
}
.petsolv-skin .adcreative-container .field-row.field-style-rcell .field-label {
  display: table-cell;
  text-align: right;
  padding-right: 4px;
  white-space: nowrap;
}
.petsolv-skin .adcreative-container .field-row.field-style-rcell .field-value {
  display: table-cell;
  padding-bottom: 4px;
}
.petsolv-skin .adcreative-container .field-row.field-style-inline {
  display: block;
  padding-bottom: 2px;
}
.petsolv-skin .adcreative-container .field-row.field-style-inline .field-label {
  display: inline;
  padding-right: 4px;
}
.petsolv-skin .adcreative-container .field-row.field-style-inline .field-value {
  display: inline;
  padding-bottom: 2px;
}
.petsolv-skin .adcreative-container .field-row.field-style-inline .field-value p:first-of-type {
  display: inline;
}
.petsolv-skin .adcreative-container .field-row.field-style-newline {
  display: block;
  height: 100%;
}
.petsolv-skin .adcreative-container .field-row.field-style-newline .field-label {
  display: block;
  text-align: left;
}
.petsolv-skin .adcreative-container .field-row.field-style-newline .field-value {
  display: block;
  padding-bottom: 2px;
  height: 100%;
  text-align: left;
}
.petsolv-skin .adcreative-container .field-row.field-style-label-bold .field-label {
  font-weight: 700;
}
.petsolv-skin .adcreative-container .field-row.field-style-value-bold .field-value {
  font-weight: 700;
}
.petsolv-skin .adcreative-container .field-row.field-style-label-hidden .field-label {
  display: none;
}
.petsolv-skin .adcreative-container .field-row.field-style-label-hidden .field-value {
  display: block;
}

.petsolv-skin .adcreative-container .fields input {
  font-family: "Verdana", "sans-serif", "serif";
  font-size: 10pt;
  font-weight: normal;
  text-align: left;
  width: 100%;
  outline: 0;
  border: 0;
}

.petsolv-skin .adcreative-container .fields input:focus {
  background-color: #eee;
  border: 0;
}

.petsolv-skin .adcreative-container .fields input:hover {
  background-color: #eee;
  border: 0;
}

.petsolv-skin .adcreative-container .fields textarea {
  font-size: 10pt;
  font-weight: normal;
  width: 100%;
  resize: none;
  outline: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: transparent;
  border: 0;
}

.petsolv-skin .adcreative-container .fields textarea:focus {
  background-color: #eee;
  border: 0;
}

.petsolv-skin .adcreative-container .fields textarea:hover {
  background-color: #eee;
  border: 0;
}

/*.petsolv-skin .rsw-editor:focus {*/
/*  background-color: #eee;*/
/*  border: 0;*/
/*}*/

/*.petsolv-skin .rsw-editor:hover {*/
/*  background-color: #eee;*/
/*  border: 0;*/
/*}*/

.petsolv-skin .rsw-editor .rsw-ce {
  padding: 2px;
}

.petsolv-skin .rsw-editor .rsw-ce:hover {
  background-color: #eee;
  border: 0;
}

.petsolv-skin .rsw-editor .rsw-ce:focus {
  background-color: #eee;
  border: 0;
}

.petsolv-skin .rsw-toolbar {
  font-size: 8pt;
  border: 1px solid #ccc;
}

