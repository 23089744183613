.image-backdrop {
  display: block;
  background-color: var(--form-control-background-color);
  border: 1px solid var(--form-control-border-color) !important;
}

.image-backdrop.readonly {
  background-color: var(--form-control-inactive-background-color) !important;
}

.image-clear-button {
  position: absolute;
  top: 1px;
  right: 0;
  width: 20px;
  height: 24px;
  color: var(--button-text-color);
  background-color: var(--button-background-color);
  padding: 0px;
  border: 0;
}

input.upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

input.upload + label {
  cursor: pointer;
  background-color: var(--form-control-background-color);
  border: 1px solid var(--form-control-border-color);
  outline: 0;
}

input.upload + label:hover {
  opacity: 1;
  outline: 0;
  background-color: var(--form-control-active-background-color);
}

input[disabled].upload + label {
  cursor: default;
}

input[disabled].upload + label:hover {
  opacity: 1;
}

.upload:focus + label,
.upload + label:hover {
  /*border: 1px solid rgb(62	90	124);*/
}

.upload:focus + label,
.upload + label:focus {
  -webkit-box-shadow: 0 0 0 1px var(--form-control-active-border-color) !important;
  box-shadow: 0 0 0 1px var(--form-control-active-border-color) !important;
}
