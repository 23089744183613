.signin-signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background-color) !important;
}

.signin-signup-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--form-background-color) !important;
  border: 1px solid var(--form-border-color);
  border-radius: 8px;
}
  
.signin-signup-card header {
  border-bottom: 1px solid var(--form-border-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
}

.signin-signup-card header h1 {
  color: var(--form-header-text-color);  
  font-size: 20px;
  margin-bottom: 4px !important;
}

.signin-signup-card header small {
  color: var(--form-caption-color);
}
  
/* .signin-signup-card img {
  width: 200px;
  height: auto;
  padding: 10px;
} */

.signin-signup-card .form-card {
  background-color: var(--form-background-color) !important;
  border: 1px solid transparent !important;
}
  
.signin-signup-card main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 800px;
  padding: 20px 40px 20px 40px ;
  background-color: var(--form-background-color) !important;
  border-radius: 8px;
}
  
.signin-signup-card main section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 18px;
  text-align: center;
} 
  
.signin-signup-card main section h1 {
  color: var(--form-header-text-color);
  font-size: 20px;
  margin-bottom: 16px;
}

.signin-signup-card main section h2 {
  color: var(--form-header-text-color);  
  font-size: 15px;
  font-weight: 400 !important;
  margin-bottom: 16px;
}

.signin-signup-card a {
  color: var(--form-link-text-color);
  text-decoration: none;
  border-bottom: 1px solid var(--form-link-underline-color);
}
  
.signin-signup-card p {
  font-size: 14px;
  color: var(--form-control-label-color);
  line-height: 24px;
  padding: 0px;
  margin: 0px;
  text-align: center;
}
  
.signin-signup-card .btn-login {
  padding: 0;
  /* margin: 20px; */
  border: 0;
  outline: 0;
  background-color: #333;
}
  
.signin-signup-card .btn-login:focus {
  border: 0;
  outline: 0;  
}
  
.signin-signup-card footer {
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  color: var(--form-control-text-color);
  padding: 14px;
  border-top: 1px solid #555;
}
  