.account-status-color-inviting {
  color: var(--tag-label-todo-text-color) !important;
}
.account-status-color-onboarding {
  color: var(--tag-label-warning-text-color) !important;
}
.account-status-color-inactive {
  color: var(--tag-label-error-text-color) !important;
}
.account-status-color-active {
  color: var(--tag-label-doing-text-color) !important;
}
.account-status-color-unused {
  color: var(--tag-label-warning-text-color) !important;
}
.account-status-color-dormant {
  color: var(--tag-label-warning-text-color) !important;
}
.account-status-color-blocked {
  color: var(--tag-label-error-text-color) !important;
}
.account-status-color-banned {
  color: var(--tag-label-error-text-color) !important;
}
.account-status-color-unknown {
  color: var(--tag-label-error-text-color) !important;
}
