:root {
  --alert-info-text-color: #111;
  --alert-info-title-color: #111;
  --alert-info-background-color: #ddd;
  --alert-info-icon-color: #111;
  --alert-info-icon-background-color: #ddd;
  --alert-info-border-color: #c6c8ca;

  --alert-attention-text-color: #004085;
  --alert-attention-title-color: #004085;
  --alert-attention-background-color: #cce5ff;
  --alert-attention-icon-color: #004085;
  --alert-attention-icon-background-color: #cce5ff;
  --alert-attention-border-color: #b8daff;

  --alert-success-text-color: #155724;
  --alert-success-title-color: #56bca6;
  --alert-success-background-color: #d4edda;
  --alert-success-icon-color: #155724;
  --alert-success-icon-background-color: #d4edda;
  --alert-success-border-color: #b1e6bd;

  --alert-warning-text-color: #856404;
  --alert-warning-title-color: #856404;
  --alert-warning-background-color: #fff3cd;
  --alert-warning-icon-color: #856404;
  --alert-warning-icon-background-color: #ffe697; /*#fff3cd;*/
  --alert-warning-border-color: #ffdf7f;

  --alert-error-text-color: #721c24;
  --alert-error-title-color: #721c24;
  --alert-error-background-color: #f8d7da;
  --alert-error-icon-color: #721c24;
  --alert-error-icon-background-color: #f8d7da;
  --alert-error-border-color: #f5b3ba;

  --alert-fatal-text-color: #721c24;
  --alert-fatal-title-color: #721c24;
  --alert-fatal-background-color: #f5b3ba;
  --alert-fatal-icon-color: #721c24;
  --alert-fatal-icon-background-color: #f5b3ba;
  --alert-fatal-border-color: #721c24;
}

@media (prefers-color-scheme: dark) {
  :root {
    --alert-info-text-color: #222;
    --alert-info-title-color: #000;
    --alert-info-background-color: #777;
    --alert-info-icon-color: #ccc;
    --alert-info-icon-background-color: #666;
    --alert-info-border-color: #555;

    --alert-attention-text-color: #000;
    --alert-attention-title-color: #000;
    --alert-attention-background-color: #64a9e0;
    --alert-attention-icon-color: #7babf6;
    --alert-attention-icon-background-color: #3d6cb4;
    --alert-attention-border-color: #294e88;

    --alert-success-text-color: #0c3315;
    --alert-success-title-color: #0c3315;
    --alert-success-background-color: #67c37d;
    --alert-success-icon-color: #17ff09;
    --alert-success-icon-background-color: #57ac6b;
    --alert-success-border-color: #155724;

    --alert-warning-text-color: #643c00;
    --alert-warning-title-color: #432800;
    --alert-warning-background-color: #f9d15a;
    --alert-warning-icon-color: #fff9f0;
    --alert-warning-icon-background-color: #ffbf60;
    --alert-warning-border-color: #f29306;
    --alert-warning-link-text-color: #fff;
    --alert-warning-link-border-color: #fff;

    --alert-error-text-color: #eee; /*#5f0000;*/
    --alert-error-title-color: #fff; /*#410000;*/
    --alert-error-hilite-color: #fff; /*#410000;*/
    --alert-error-background-color: #8d121d; /*#c97980;*/
    --alert-error-icon-color: #fff; /*#f6cbcf;*/
    --alert-error-icon-background-color: #ed0217; /*#d6636d;*/
    --alert-error-border-color: #ba4853;
    --alert-error-link-text-color: #fff;
    --alert-error-link-border-color: #d2aeae;
    --alert-error-link-focus-text-color: #fff;
    --alert-error-link-focus-border-color: #fff;

    --alert-fatal-text-color: #5f0000;
    --alert-fatal-title-color: #410000;
    --alert-fatal-background-color: #c97980;
    --alert-fatal-icon-color: #f6cbcf;
    --alert-fatal-icon-background-color: #d6636d;
    --alert-fatal-border-color: #ba4853;
  }
}

/* form-header --- */

.form-header {
  display: block;
  font-size: 12pt;
  top: var(--main-menu-height);
  color: var(--form-header-text-color);
  background-color: rgba(var(--form-header-background-color-rgb), var(--form-header-background-color-alpha)) !important; /*var(--form-header-background-color) !important;*/
  border-bottom: 1px solid var(--form-header-border-color);
  z-index: 9;
}

.form-header-push {
  margin-top: var(--main-menu-height);
  /* margin-bottom: 2px;  */
}

.form-header .divider {
  height: 1px;
  margin: 0 var(--form-header-padding-width);
  border-top: 1px solid var(--form-header-border-color);
}

.form-header .toolbar {
  display: flex;
  font-size: 12pt;
  min-height: var(--form-header-toolbar-min-height);
  height: fit-content;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: start;
  color: var(--form-header-text-color);
  background-color: rgba(var(--form-header-background-color-rgb), var(--form-header-background-color-alpha)) !important;; /*var(--form-header-background-color) !important;*/
  padding: var(--form-header-toolbar-padding-height) var(--form-header-padding-width);
  /*gap: 8px;*/
}

.form-header .toolbar .title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 15pt;
  font-weight: 500;
  color: var(--form-header-toolbar-title-text-color);
  padding: 4px 0;
  margin: 0;
  gap: 0.25em;
}

.form-header .toolbar h1 {
  font-size: 15pt;
  font-weight: 500;
  color: var(--form-header-toolbar-title-text-color);
  padding: 0;
  margin: 0;
}

.form-header .toolbar .title p {
  font-size: 10pt;
  font-weight: 400;
  color: var(--form-header-toolbar-title-text-color);
  padding: 0;
  margin: 0
}

.form-header .toolbar .controls {
  display: flex;
  gap: 6px;
}

.form-header .toolbar .controls .upload-container {
  position: relative;
}

.form-header .toolbar .controls input[type="file"] {
  display: none;
}

.form-header .toolbar .controls .upload {
  display: inline-block;
  cursor: pointer;
  margin: 0;
}

/*.form-header .toolbar .controls :not(:first-child) {*/
/*  margin-left: 8px !important;*/
/*}*/

/*.form-header .toolbar .controls a.btn {*/
/*  margin-left: 8px;*/
/*}*/

.form-header .toolbar .controls .alert {
  margin: 0 !important;
}

.form-header .alerts {
  /* border-top: 1px solid var(--toolbar-divider-color); */
  padding: var(--form-header-padding-height) var(--form-header-padding-width);
}

.form-header .content {
  /* border-top: 1px solid var(--toolbar-divider-color); */
  padding: var(--form-header-padding-height) var(--form-header-padding-width);
}

.form-header .tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 11pt;
  padding: var(--form-header-toolbar-padding-height) var(--form-header-padding-width);
  margin: 0 !important;
  border-bottom: 1px solid var(--form-header-border-color);
  gap: 12px;
}

.tabs-position-top {
  /*border-bottom: 1px solid var(--form-header-divider-color);*/
}

.tabs-position-bottom {
  /*border-top: 1px solid var(--form-header-divider-color);*/
}

.form-header ul.tabs li {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
}

/*.form-header ul.tabs li:not(:last-child) {*/
/*  padding-right: 18px;*/
/*}*/

/*.form-header ul.tabs li:not(:first-child) {*/
/*  padding-left: 16px;*/
/*}*/

.form-header .tabs-title {
  display: flex;
  align-items: center;
  font-size: 15pt;
  font-weight: 400;
  color: var(--menu-active-text-color);
  padding: var(--form-header-toolbar-padding-height) var(--form-header-padding-width);
  border-bottom: 1px solid var(--form-header-border-color);
}

.form-header a.tab-link {
  color: var(--menu-text-color);
  font-weight: var(--main-text-weight);
  text-decoration: none;
}
.form-header a.tab-link:hover {
  color: var(--menu-active-text-color);
  font-weight: var(--main-active-text-weight);
  text-decoration: none;
  border-bottom: 1.5px solid var(--link-underline-color);
}

.form-header a.tab-link:focus {
  color: var(--menu-active-text-color);
  font-weight: var(--main-active-text-weight);
  text-decoration: none;
  border-bottom: 1.5px solid var(--link-underline-color);
}

.form-header .tab-link.active {
  color: var(--menu-active-text-color);
  font-weight: var(--main-active-text-weight);
  text-decoration: none;
  border-bottom: 1.5px solid var(--link-underline-color);
}

/* form-body --- */

.form-body {
  color: var(--form-text-color);
  padding: var(--form-body-padding-height) var(--form-body-padding-width);
  background-color: var(--form-background-color);
}

/* -- form-label --*/
.form-label {
  display: flex;
  flex-direction: column;
  font-size: var(--form-control-font-size) !important;
  font-weight: 400;
  color: var(--form-control-label-color) !important;
  text-align: left;
  text-wrap: pretty;
  gap: 1px;
}

.form-label em {
  font-style: normal;
  font-weight: 400;
  color: var(--form-caption-bold-color);
}

.form-label-label {

}

.form-label-collapsed {
  cursor: pointer;
  margin-bottom: 0 !important;
}

.form-label-delimiter {
  color: var(--form-caption-color) !important;
}

.form-label p {
  margin: 0 !important;
  padding: 0 !important;
}

.form-label small {
  color: var(--form-caption-color) !important;
  font-size: 12px;
}

/* alert -- */

.alert_container {
  display: flex;
  width: fit-content;
  padding: 0;
  border-radius: 4px;
}

.alert_container[data-alert=info] {
  background-color: var(--alert-info-background-color);
  border: 1px solid var(--alert-info-border-color);
}

.alert_container[data-alert=attention] {
  background-color: var(--alert-attention-background-color);
  border: 1px solid var(--alert-attention-border-color);
}

.alert_container[data-alert=success] {
  background-color: var(--alert-success-background-color);
  border: 1px solid var(--alert-success-border-color);
}

.alert_container[data-alert=warning] {
  background-color: var(--alert-warning-background-color);
  border: 1px solid var(--alert-warning-border-color);
}

.alert_container[data-alert=error] {
  background-color: var(--alert-error-background-color);
  border: 1px solid var(--alert-error-border-color);
}

.alert_container[data-alert=fatal] {
  background-color: var(--alert-fatal-background-color);
  border: 1px solid var(--alert-fatal-border-color);
}

.alert_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  padding: 8px 0 8px 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.alert_icon_container[data-alert="info"] {
  color: var(--alert-info-border-color);
  /*background-color: var(--alert-info-icon-background-color);*/
  /*border-right: 1px solid var(--alert-info-border-color);*/
}

.alert_icon_container[data-alert="attention"] {
  color: var(--alert-attention-border-color);
  /*background-color: var(--alert-attention-icon-background-color);*/
  /*border-right: 1px solid var(--alert-attention-border-color);*/
}

.alert_icon_container[data-alert="success"] {
  color: var(--alert-success-border-color);
  /*background-color: var(--alert-success-icon-background-color);*/
  /*border-right: 1px solid var(--alert-success-border-color);*/
}

.alert_icon_container[data-alert="warning"] {
  color: var(--alert-warning-border-color); /*var(--alert-warning-icon-color);*/
  /*background-color: var(--alert-warning-icon-background-color);*/
  /*border-right: 1px solid var(--alert-warning-border-color);*/
}

.alert_icon_container[data-alert="error"] {
  color: var(--alert-error-border-color);
  /*background-color: var(--alert-error-icon-background-color);*/
  /*border-right: 1px solid var(--alert-error-border-color);*/
}

.alert_icon_container[data-alert="fatal"] {
  color: var(--alert-fatal-border-color);
  /*background-color: var(--alert-fatal-icon-background-color);*/
  /*border-right: 1px solid var(--alert-fatal-border-color);*/
}

.alert_content_container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  padding: 10px 12px 10px 6px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.alert_content_container[data-alert="info"] {
  color: var(--alert-info-text-color);
  background-color: var(--alert-info-background-color);
}

.alert_content_container[data-alert="attention"] {
  color: var(--alert-attention-text-color);
  background-color: var(--alert-attention-background-color);
}

.alert_content_container[data-alert="success"] {
  color: var(--alert-success-text-color);
  background-color: var(--alert-success-background-color);
}

.alert_content_container[data-alert="warning"] {
  color: var(--alert-warning-text-color);
  background-color: var(--alert-warning-background-color);
}

.alert_content_container[data-alert="error"] {
  color: var(--alert-error-text-color);
  background-color: var(--alert-error-background-color);
}

.alert_content_container[data-alert="fatal"] {
  color: var(--alert-fatal-text-color);
  background-color: var(--alert-fatal-background-color);
}

.alert_content_container h1 {
  font-size: 18px;
  font-weight: 700;
}
.alert_content_container ul {
  padding: 0 0 0 14px;
  margin: 0;
}

.alert_content_container[data-alert="error"] a {
  color: var(--alert-error-link-text-color) !important;
  text-decoration: underline !important;
  text-decoration-color: var(--alert-error-link-border-color) !important;
}

.alert_content_container[data-alert="error"] a:hover {
  color: var(--alert-error-link-focus-text-color) !important;
  text-decoration: underline !important;
  text-decoration-color: var(--alert-error-link-focus-border-color) !important;
}

.alert_content_container[data-alert="error"] a:focus {
  color: var(--alert-error-link-focus-text-color) !important;
  text-decoration: underline !important;
  text-decoration-color: var(--alert-error-link-focus-border-color) !important;
}

.alert_content_container[data-alert="info"] :global(.but) {
  color: var(--alert-info-text-color) !important;
}

.alert_content_container[data-alert="attention"] :global(.but) {
  color: var(--alert-attention-text-color) !important;
}

.alert_content_container[data-alert="error"] :global(.but) {
  color: var(--alert-error-text-color) !important;
}

.alert_content_container[data-alert="warning"] :global(.but) {
  color: var(--alert-warning-text-color) !important;
}

.alert_content_container[data-alert="info"] :global(.text-link) {
  color: var(--alert-info-text-color) !important;
  font-weight: 600 !important;
}

.alert_content_container[data-alert="attention"] :global(.text-link) {
  color: var(--alert-attention-text-color) !important;
  font-weight: 600 !important;
}

.alert_content_container[data-alert="warning"] :global(.text-link) {
  color: var(--alert-warning-text-color) !important;
  font-weight: 600 !important;
}

.alert_content_container[data-alert="error"] :global(.text-link) {
  color: var(--alert-error-text-color) !important;
  font-weight: 600 !important;
}

.alert_content_container[data-alert="fatal"] :global(.text-link) {
  color: var(--alert-fatal-text-color) !important;
  font-weight: 600 !important;
}

.busy_spinner {
  display: flex;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000061 center center no-repeat;
  /*background: black url('/images/spinner-white.svg') center center no-repeat;*/
  /*opacity: .6;*/
  align-items: center;
  justify-content: center;
}

.busy_spinner_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14pt;
  font-weight: 400;
  color: #ccc;
  background-color: #00000061;
  padding: 40px;
  border-radius: 5px;
}

.section_header {
  color: var(--form-label-color);
  padding-bottom: 10px;
  border-bottom: 1px solid var(--form-divider-color) !important;
  margin: 0 0 20px 0;
}

.section_header:not(:first-child) {
  margin-top: 30px;
}

.section_header > h1 {
  font-size: 11pt;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.section_header > p {
  font-size: 12px;
  font-weight: 400;
  margin: 3px 0 0 0;
  padding: 0;
}

.section_header > small {
  font-size: 8pt;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 4px;
  background: #000;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
}

.loading:after {
  content: '';
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  animation: loading-anim 5s infinite;
}

.select-list {
  display: block;
  font-size: var(--form-control-font-size) !important;
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-background-color) !important;
  border: 1px solid var(--form-control-border-color) !important;
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 10px;
}

.select-list-item {
  color: var(--form-control-react-select-option-text-color);
  background-color: var(--form-control-react-select-option-background-color);
  border: 1px solid var(--form-control-react-select-option-border-color);
  cursor: pointer;
  margin-left: 0;
  padding: 6px;
}

.select-list-item:hover {
  color: var(--form-control-react-select-option-focus-text-color);
  background-color: var(--form-control-react-select-option-focus-background-color);
  border: 1px solid var(--form-control-react-select-option-focus-border-color);
}

.select-list-item[data-selected="true"] {
  color: var(--form-control-react-select-option-text-color);
  background-color: var(--form-control-react-select-option-background-color);
  border: 1px solid var(--form-control-react-select-option-focus-border-color);
}

.select-list-item[data-selected="true"]:hover {
  color: var(--form-control-react-select-option-focus-text-color);
  background-color: var(--form-control-react-select-option-focus-background-color);
  border: 1px solid var(--form-control-react-select-option-focus-border-color);
}

@keyframes loading-anim {
  0% {
    width: 0;
    background: #a28089;
  }

  25% {
    width: 25%;
    background: #a0d2eb;
  }

  50% {
    width: 50%;
    background: #ffa8b6;
  }

  75% {
    width: 75%;
    background: #d0bdf4;
  }

  100% {
    width: 100%;
    background: #fff;
  }
}

/*@keyframes xx {*/
/*  0% {*/
/*    background: #a28089;*/
/*  }*/

/*  25% {*/
/*    background: #a0d2eb;*/
/*  }*/

/*  50% {*/
/*    background: #ffa8b6;*/
/*  }*/

/*  75% {*/
/*    background: #d0bdf4;*/
/*  }*/

/*  100% {*/
/*    background: #494d5f;*/
/*  }*/
/*}*/
