.widget-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*margin-bottom: 20px;*/
}

.widget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 110px;
  padding: 12px;
  background-color: var(--button-background-color); /* var(--widget-background-color); #2f2f2f;*/
  border: 1px solid var(--button-border-color); /*var(--widget-border-color); #444;*/
  border-radius: 5px;
  margin: 0.15rem;
}

.widget.centered {
  align-items: center;
}

.widget h3 {
  color: var(--button-title-color) !important; /*#aaa;*/
  font-size: 20px;
  margin: 0;
}

.widget h4 {
  color: var(--button-title-color) !important;/*#aaa;*/
  font-size: 11px !important;
  margin: 0;
  text-transform: uppercase;
}

.widget .title {
  color: var(--button-caption-color) !important;/*#aaa;*/
  font-size: 8pt !important;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}

.widget .subtitle {
  color: var(--button-caption-color) !important;/*#aaa;*/
  font-size: 7pt !important;
  font-weight: 400;
  margin: 0;
  text-transform: lowercase;
}

.widget .p {
  color: var(--button-text-color) !important;/*#aaa;*/
  font-size: 11px !important;
  margin: 0;
  text-transform: uppercase;
}

.widget .p {
  color: var(--button-text-color) !important;/*#aaa;*/
  font-size: 11px !important;
  margin: 0;
  text-transform: uppercase;
}

.widget .body {
  display: flex;
  justify-content: flex-start;
  /*margin-top: 10px*/
  width: 100%;
}

.widget .icon {
  font-size: 18pt;
}

.widget p {
  color: var(--button-text-color); /*white*/
  font-size: 20px;
  margin: 0;
}

.widget.clickable {
  background-color: var(--button-background-color) !important;
  cursor: pointer;
}

.widget.clickable:hover {
  background-color: var(--button-active-background-color) !important;
}

.widget.clickable p {
  color: var(--button-active-text-color) !important;
  font-size: 20px;
  margin: 0;
}

.widget small {
  font-size: 8pt;
}

.widget .mini-chart {
  position: relative;
  width: 60px;
  height: 44px;
  margin: 0 0.5rem 0 0;
  border: 0;
}

.widget .metric {
  font-size: 24pt;
  color: var(--button-title-color);
}

.widget .metric small {
  font-size: 7pt;
  color: var(--button-caption-color);
}

.widget .footer {
  color: var(--button-title-color);
  font-size: 9px;
  margin: 8px 0 0 0;
}


