:root {
  --lang-text-color: #888;
  --lang-background-color: var(--button-background-color);
  --lang-border-color: var(--button-border-color);
  --lang-filled-text-color: #ccc;
  --lang-filled-background-color: #ccc;
  --lang-selected-text-color: var(--button-active-text-color);
  --lang-selected-background-color: var(--button-active-background-color);
}

@media (prefers-color-scheme: dark) {
  :root {
    --lang-text-color: #999;
    --lang-background-color: var(--button-background-color);
    --lang-border-color: var(--button-border-color);
    --lang-filled-text-color: #999;
    --lang-filled-background-color: #555;
    --lang-selected-text-color: var(--button-active-text-color);
    --lang-selected-background-color: var(--button-active-background-color);
  }
}

.langs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1px;
  margin-top: 1px;
  border: 1px solid var(--form-control-border-color);
  border-radius: var(--form-control-border-radius);
}

.lang {
  text-wrap: nowrap;
  padding: 6px 6px;
  color: var(--lang-text-color);
  background-color: var(--lang-background-color);
  border: 1px solid var(--lang-border-color);
  cursor: pointer;
}

.lang.selected {
  color: var(--lang-selected-text-color);
  background-color: var(--lang-selected-background-color);
  /*box-shadow: 0 0 0 1px var(--form-control-border-focus-color) !important;*/
}

div.lang {
  position: relative;
}
div.lang.selected::before {
  content: '';
  position: absolute;
  top: 18px; /* Adjust this to position the triangle correctly */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 20px solid transparent; /* Half the width of the triangle */
  border-right: 20px solid transparent; /* Half the width of the triangle */
  border-bottom: 20px solid #3498db; /* Color of the triangle */
}

.lang.filled {
  color: var(--lang-filled-text-color);
  background-color: var(--lang-filled-background-color);
  /*font-weight: 700;*/
}

.lang.selected.filled {
  color: var(--lang-selected-text-color);
  background-color: var(--lang-selected-background-color);
}

.lang:hover {
  background-color: var(--lang-selected-background-color);
}