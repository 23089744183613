:root {
  --logo-uri: "https://sassets.solv.technology/brand/{brandId}-logo-{color}-{mode}-md-c.png";
  --logo-size: "44x14";

  --main-menu-height: 44px;
  --nav-top: 80px;

  --main-font-size: 0.9375rem;
  --main-text-weight: 400;
  --main-active-text-weight: 400;
  --main-menu-text-weight: 500;
  --main-menu-active-text-weight: 500;

  --main-padding-width: 0;
  --main-padding-height: 0;

  --menu-font-size: var(--main-font-size);
  --menu-underscore-thickness: 1.5px;
  --menu-underscore-offset: 3px;

  --form-group-padding: 18px;

  --form-header-padding-width: 20px;
  --form-header-padding-height: 1em;
  --form-header-toolbar-padding-width: 8px;
  --form-header-toolbar-padding-height: 8px;
  --form-header-toolbar-min-height: 56px;

  --form-body-padding-width: 1.5em;
  --form-body-padding-height: 1.5em;

  --form-control-font-size: 0.9375rem;
  --form-control-height: 2.5em;
  --form-control-border-radius: 0.25rem;

  --form-helptext-font-size: 0.75em;

  --table-header-text-weight: 700;
}

/* themes */

:root {
  --theme: light;
  --mode: light;

  --text-color: #222;
  --title-color: #000; /*#c55800;*/
  --caption-color: #888;
  --label-color: #585858; /*#555;*/
  --background-color: #ccc;

  --link-underline-color: #ce0903;

  --menu-text-color: #111;
  --menu-title-color: #000;
  --menu-background-color:  #f0f0f0;
  --menu-active-text-color: #000;
  --menu-border-color: #dfdfdf;
  --menu-link-text-color: #111;
  --menu-link-background-color: #f0f0f0;
  --menu-link-border-color: #f0f0f0;
  --menu-link-underline-color: #f0f0f0;
  --menu-link-active-text-color: #000;
  --menu-link-active-background-color: #f0f0f0;
  --menu-link-active-border-color: #f0f0f0;
  --menu-link-active-underline-color: var(--link-underline-color); /*#cecece; #872205;*/

  --button-text-color: #333;
  --button-title-color: #000;
  --button-caption-color: #888;
  --button-background-color: #fefefe;
  --button-border-color: #d5d5d5;
  --button-active-text-color:  #000;
  --button-active-background-color: #efefef;
  --button-active-border-color: #d5d5d5;
  --button-inactive-text-color: #4a4a4a;
  --button-inactive-background-color: #cacaca;
  --button-inactive-border-color: #959595;

  --primary-button-text-color: #fff;
  --primary-button-background-color: #952300;
  --primary-button-border-color: #b22a00;
  --primary-button-active-text-color: #fff;
  --primary-button-active-background-color: #521604;
  --primary-button-active-border-color: #ad4f34;

  --tertiary-button-text-color: #000;
  --tertiary-button-background-color: transparent;
  --tertiary-button-border-color: var(--form-background-color);
  --tertiary-button-active-text-color:  var(--button-active-text-color);
  --tertiary-button-active-background-color: var(--button-active-background-color);
  --tertiary-button-active-border-color: var(--form-background-color);

  --primary-blue-button-text-color: #ffffff;
  --primary-blue-button-background-color: #0d6efd;
  --primary-blue-button-border-color: #0d6efd;
  --primary-blue-button-active-text-color:  #ffffff;
  --primary-blue-button-active-background-color: #0b5ed7;
  --primary-blue-button-active-border-color: #0d6efd;

  --primary-violet-button-text-color: #ffffff;
  --primary-violet-button-background-color: #712cf9;
  --primary-violet-button-border-color: #712cf9;
  --primary-violet-button-active-text-color: #fffff;
  --primary-violet-button-active-background-color: #6528e0;
  --primary-violet-button-active-border-color: #712cf9;

  --form-header-text-color: #111;
  --form-header-background-color-rgb: 227, 227, 227;
  --form-header-background-color-alpha: 1;
  --form-header-border-color: #ccc;
  --form-header-shadow: 0 4px 2px -2px #666;

  --form-header-toolbar-title-text-color: #000;

  --form-text-color: var(--text-color);
  --form-title-color: var(--title-color);
  --form-label-color: var(--label-color);
  --form-background-color: #c5c5c5;
  --form-background-color-rgb: #c5c5c5;
  --form-background-color-alpha: 1;
  --form-background-opacity: 100%;
  --form-border-color: #999;
  --form-border-shadow: rgba(63,63,63,0.48);

  --form-caption-color: var(--caption-color);
  --form-caption-kbd-color: var(--title-color);
  --form-caption-mark-color: var(--title-color);
  --form-caption-bold-color: var(--title-color);

  --form-divider-text-color: #555;
  --form-divider-color: #b4b4b4;

  --form-link-text-color: #111;
  --form-link-icon-color: #111;
  --form-link-underline-color: #bbb;
  --form-link-active-text-color: #000;
  --form-link-active-icon-color: #000;
  --form-link-active-underline-color: var(--link-underline-color);

  --form-group-background-color: #e3e3e3;
  --form-group-border-color: #b5b5b5;

  --form-control-text-color: #000;

  --form-control-label-color: #111;
  --form-control-label-emphasized-color: #111;

  --form-control-background-color: #d8d8d8;
  --form-control-border-color: #bbb;
  --form-control-active-text-color: #000;
  --form-control-active-background-color: #f8f8f8;
  --form-control-active-border-color: #2684FF;
  --form-control-inactive-background-color: var(--form-group-background-color);

  --form-control-dropdown-header-background-color: #4c4c4c;

  --form-control-react-select-option-text-color: black;
  --form-control-react-select-option-background-color: #f8f8f8; /*#ccc;*/
  --form-control-react-select-option-border-color: transparent;
  --form-control-react-select-option-focus-text-color: #000;
  --form-control-react-select-option-focus-background-color: #eee;
  --form-control-react-select-option-focus-border-color: #ccc; /*#ccc;*/
  --form-control-react-select-option-selected-text-color: #000;
  --form-control-react-select-option-selected-background-color: var(--form-group-background-color); /*#f8f8f8;*/
  --form-control-react-select-option-selected-border-color: #f8f8f8;
  --form-control-react-select-option-disabled-text-color: #ccc;
  --form-control-react-select-multivalue-text-color: black;
  --form-control-react-select-multivalue-background-color: #f8f8f8;
  --form-control-react-select-multivalue-border-color: #bbb;
  --form-control-react-select-multivalue-selected-background-color: #fff;
  --form-control-react-select-multivalue-disabled-text-color: #333;
  --form-control-react-select-multivalue-disabled-background-color: var(--form-group-background-color);
  --form-control-react-select-multivalue-remove-text-color: #222;
  --form-control-react-select-multivalue-remove-background-color: #f8f8f8;
  --form-control-react-select-multivalue-remove-border-color: #bbb;
  --form-control-react-select-multivalue-remove-hover-text-color: #000;
  --form-control-react-select-multivalue-remove-hover-background-color: #fff;
  --form-control-error-color: #e74c3c;
  --form-control-warning-color: #d18001;

  --nav-text-color: #ccc;
  --nav-focus-color: white;

  --table-background-color: #f0f0f0;
  --table-border-color: #e0e0e0;
  --table-header-text-color: #444;
  --table-header-background-color: #e3e3e3; /*#f0f0f0;*/
  --table-header-border-color: #999;
  --table-header-hilite-text-color: #000;
  --table-header-hilite-background-color: #cecece;
  --table-row-odd-background-color: #e8e8e8; /*#e5e5e5;*/
  --table-hilite-text-color: #000;
  --table-hilite-background-color: #cecece; /*#6f1e06;*/
  --table-unread-text-color: #000;
  --table-unread-selected-text-color: #000;

  --widget-text-color: #222;
  --widget-header-text-color: #838383;
  --widget-footer-text-color: #737373;
  --widget-metric-title-color: #222;
  --widget-metric-text-color: #222;
  --widget-metric-footer-color: #555;
  --widget-background-color: #e0e0e0;
  --widget-clickable-background-color: #e3e3e3;
  --widget-selected-background-color: #ffffff;
  --widget-border-color: #bbb;

  --tag-background-color: #e8e8e0;
  --tag-border-color: #999;
  --tag-label-default-text-color: #555;
  --tag-label-inactive-text-color: #d7ccc8;
  --tag-label-todo-text-color: #7b8fff;
  --tag-label-doing-text-color: #0dbc5f;
  --tag-label-done-text-color:  #00aaff;
  --tag-label-error-text-color: #ee8b84;
  --tag-label-warning-text-color: #db9439;
  --tag-label-hilite-text-color: #000;

  --color-subtext: #888;
  --color-warning: #c86e05; /*#fe8d59;*/
  --color-danger: red;

  --kbd-text-color: #333;
  --kbd-border-color: #999;

  --background-spinner-tiny: rgb(var(--form-background-color), 1) url('/images/spinner-tiny-black.svg') center center no-repeat;
}

[data-theme="dark"] {
  --theme: dark;
  --mode: dark;

  --text-color: #ddd;
  --title-color: #c8c6c6;
  --caption-color: #787777;
  --label-color: #989797;
  --background-color: #101010;
  --divider-color: #333;
  --border-color: #333;

  --link-underline-color: #ce0903;

  --menu-text-color: #aaa;
  --menu-background-color: #000;
  --menu-border-color: #353535;
  --menu-active-text-color: #fff;
  --menu-title-color: #fff;
  --menu-link-text-color: #aaa;
  --menu-link-background-color: #000;
  --menu-link-border-color: #000;
  --menu-link-underline-color: #000;
  --menu-link-active-text-color: #fff;
  --menu-link-active-background-color: #000;
  --menu-link-active-border-color: #000;
  --menu-link-active-underline-color: var(--link-underline-color);

  --button-text-color: #eee;
  --button-title-color: #fff;
  --button-caption-color: #ccc;
  --button-background-color: #444;
  --button-border-color: #555;
  --button-active-text-color: white;
  --button-active-background-color: #313131;
  --button-active-border-color: #666;
  --button-inactive-text-color: #b5b5b5;
  --button-inactive-background-color: #353535;
  --button-inactive-border-color: #454545;

  --primary-button-text-color: white;
  --primary-button-background-color: #952300;
  --primary-button-border-color: #b22a00;
  --primary-button-active-text-color: #fff;
  --primary-button-active-background-color: #782006; /*#521604;*/
  --primary-button-active-border-color: #ad4f34;

  --tertiary-button-text-color: white;
  --tertiary-button-background-color: transparent;
  --tertiary-button-border-color: var(--form-background-color);
  --tertiary-button-active-text-color: var(--button-active-text-color);
  --tertiary-button-active-background-color: var(--button-active-background-color);
  --tertiary-button-active-border-color: var(--form-background-color);

  --primary-blue-button-text-color: #ffffff;
  --primary-blue-button-background-color: #0d6efd;
  --primary-blue-button-border-color: #0d6efd;
  --primary-blue-button-active-text-color: #ffffff;
  --primary-blue-button-active-background-color: #0b5ed7;
  --primary-blue-button-active-border-color: #0d6efd;

  --primary-violet-button-text-color: #ffffff;
  --primary-violet-button-background-color: #712cf9;
  --primary-violet-button-border-color: #712cf9;
  --primary-violet-button-active-text-color: #fffff;
  --primary-violet-button-active-background-color: #6528e0;
  --primary-violet-button-active-border-color: #712cf9;

  --form-header-text-color: white;
  --form-header-background-color-rgb: 26, 26, 26;
  --form-header-background-color-alpha: 1;
  --form-header-border-color: #353535;
  --form-header-shadow: 0 4px 2px -2px #111;

  --form-header-toolbar-title-text-color: #fff;

  --form-text-color: var(--text-color);
  --form-title-color: var(--title-color);
  --form-label-color: var(--label-color);
  --form-caption-color: var(--caption-color);
  --form-background-color: #222222;
  --form-background-color-rgb: #222222;
  --form-background-color-alpha: 1;
  --form-background-opacity: 100%;
  --form-border-color: #444;
  --form-border-shadow: rgba(0, 0, 0, 0.77);

  --form-caption-color: var(--caption-color);
  --form-caption-kbd-color: var(--label-color);
  --form-caption-mark-color: var(--label-color);
  --form-caption-bold-color: var(--label-color);

  --form-divider-text-color: var(--label-color);
  --form-divider-color: var(--divider-color);

  --form-link-text-color: #eee;
  --form-link-icon-color: #ccc;
  --form-link-underline-color: #555;
  --form-link-active-text-color: #fff;
  --form-link-active-icon-color: #fff;
  --form-link-active-underline-color: var(--link-underline-color);

  --form-group-background-color: #2a2a2a;
  --form-group-border-color: #3c3c3c;

  --form-control-text-color: #fff;

  --form-control-label-color: var(--label-color);
  --form-control-label-emphasized-color: #fff;

  --form-control-background-color: #383838;
  --form-control-border-color: #555;
  --form-control-active-background-color: #494848;
  --form-control-active-border-color: #2684FF;
  --form-control-inactive-background-color: var(--form-group-background-color);

  --form-control-dropdown-header-background-color: #4c4c4c;

  --form-control-react-select-option-text-color: #fff;
  --form-control-react-select-option-background-color: var(--form-control-background-color);
  --form-control-react-select-option-border-color: #383838; /*#ccc;*/
  --form-control-react-select-option-focus-text-color: #fff;
  --form-control-react-select-option-focus-background-color: var(--form-control-active-background-color);
  --form-control-react-select-option-focus-border-color: #555; /*#ccc;*/
  --form-control-react-select-option-selected-text-color: #fff;
  --form-control-react-select-option-selected-background-color: #383838;
  --form-control-react-select-option-selected-border-color: #383838;
  --form-control-react-select-option-disabled-text-color: #444;
  --form-control-react-select-multivalue-text-color: #fff;
  --form-control-react-select-multivalue-background-color: #383838;
  --form-control-react-select-multivalue-border-color: #555;
  --form-control-react-select-multivalue-selected-background-color: var(--form-control-active-background-color);
  --form-control-react-select-multivalue-disabled-text-color: white;
  --form-control-react-select-multivalue-disabled-background-color: var(--form-group-background-color);
  --form-control-react-select-multivalue-remove-text-color: #eee;
  --form-control-react-select-multivalue-remove-background-color: #383838;
  --form-control-react-select-multivalue-remove-border-color: #555;
  --form-control-react-select-multivalue-remove-hover-text-color: #888;
  --form-control-react-select-multivalue-remove-hover-background-color: #383838;
  --form-control-error-color: #e74c3c;
  --form-control-warning-color: #cf9944;

  --nav-text-color: #ccc;
  --nav-focus-color: white;

  --dropdown-menu-text-color: #ddd;
  --dropdown-menu-background-color: #111;
  --dropdown-menu-border-color: #333;
  --dropdown-item-selected-text-color: white;
  --dropdown-item-muted-text-color: #888;
  --dropdown-item-selected-background-color: #872205;
  --dropdown-divider-color: #333;

  --table-background-color: #303030;
  --table-border-color: #222;
  --table-header-text-color: #bbb;
  --table-header-background-color: #1b1b1b;
  --table-header-border-color: #434343;
  --table-header-hilite-text-color: #fff;
  --table-header-hilite-background-color: #111;
  --table-row-odd-background-color: #292929;
  --table-hilite-text-color: white;
  --table-hilite-background-color: #252525; /*#6f1e06;*/
  --table-unread-text-color: #fff;
  --table-unread-selected-text-color: #fff;

  --widget-text-color: #aaa;
  --widget-header-title-color: #aaa;
  --widget-header-text-color: #888;
  --widget-footer-title-color: #888;
  --widget-footer-text-color: #888;
  --widget-metric-title-color: #fff;
  --widget-metric-text-color: #ddd;
  --widget-metric-footer-color: #888;
  --widget-background-color: #2f2f2f;
  --widget-clickable-background-color: #383838;
  --widget-selected-background-color: #282828;
  --widget-border-color: #444;

  --tag-background-color: transparent;
  --tag-border-color: #484848;
  --tag-label-default-text-color: #d7ccc8;
  --tag-label-inactive-text-color: #999;
  --tag-label-todo-text-color: #b3befe;
  --tag-label-doing-text-color: #9FE2BF;
  --tag-label-done-text-color: #66CCFF;
  --tag-label-error-text-color: #ee8b84;
  --tag-label-warning-text-color: #d19c57;
  --tag-label-hilite-text-color: #ccc;

  --color-danger: #e55252;
  --color-warning: #fe8d59;
  --color-subtext: #888;

  --kbd-text-color: #ccc;
  --kbd-border-color: #555;

  --background-spinner-tiny: var(--form-background-color) url('/images/spinner-tiny-white.svg') center center no-repeat;
}

[data-theme="violetnoir"] {
  --theme: violetnoir;
  --mode: dark;

  --text-color: #ccc;
  --title-color: #b75ac7;
  --caption-color: #947ead;
  --label-color: #bd8ce2;
  --background-color: #101010;
  --border-color: #9a7061;
  --divider-color: #9a7061;

  --link-underline-color: #ce0903;

  --menu-text-color: #aaa;
  --menu-background-color: #000;
  --menu-border-color: #353535;
  --menu-active-text-color: #fff;
  --menu-title-color: #fff;
  --menu-link-text-color: #aaa;
  --menu-link-background-color: #000;
  --menu-link-border-color: #000;
  --menu-link-underline-color: #000;
  --menu-link-active-text-color: #fff;
  --menu-link-active-background-color: #000;
  --menu-link-active-border-color: #000;
  --menu-link-active-underline-color: var(--link-underline-color);

  --button-text-color: #333;
  --button-title-color: #000;
  --button-caption-color: #555;
  --button-background-color: #eeeeee;
  --button-border-color: #e5e5e5;
  --button-active-text-color:  #000;
  --button-active-background-color: #c3c2c2;
  --button-active-border-color: #d5d5d5;
  --button-inactive-text-color: #b5b5b5;
  --button-inactive-background-color: #353535;
  --button-inactive-border-color: #454545;

  --primary-button-text-color: white;
  --primary-button-background-color: #952300;
  --primary-button-border-color: #b22a00;
  --primary-button-active-text-color: #fff;
  --primary-button-active-background-color: #782006;
  --primary-button-active-border-color: #ad4f34;

  --tertiary-button-text-color: white;
  --tertiary-button-background-color: transparent;
  --tertiary-button-border-color: var(--form-background-color);
  --tertiary-button-active-text-color: var(--button-active-text-color);
  --tertiary-button-active-background-color: var(--button-active-background-color);
  --tertiary-button-active-border-color: var(--form-background-color);

  --primary-blue-button-text-color: #ffffff;
  --primary-blue-button-background-color: #0d6efd;
  --primary-blue-button-border-color: #0d6efd;
  --primary-blue-button-active-text-color: #ffffff;
  --primary-blue-button-active-background-color: #0b5ed7;
  --primary-blue-button-active-border-color: #0d6efd;

  --primary-violet-button-text-color: #ffffff;
  --primary-violet-button-background-color: #712cf9;
  --primary-violet-button-border-color: #712cf9;
  --primary-violet-button-active-text-color: #fffff;
  --primary-violet-button-active-background-color: #6528e0;
  --primary-violet-button-active-border-color: #712cf9;

  --form-header-text-color: white;
  --form-header-background-color-rgb: 31, 11, 64;
  --form-header-background-color-alpha: 1;
  --form-header-border-color: #351f5c;
  --form-header-shadow: 0 4px 2px -2px #111;

  --form-header-toolbar-title-text-color: #fff;

  --form-text-color: var(--text-color);
  --form-title-color: var(--title-color);
  --form-label-color: var(--label-color);
  --form-background-color: rgb(63	14	64);
  --form-background-color-rgb: rgb(63	14	64);
  --form-background-color-alpha: 1;
  --form-background-opacity: 100%;
  --form-border-color: #444;
  --form-border-shadow: rgba(0, 0, 0, 0.77);

  --form-caption-color: var(--caption-color);
  --form-caption-kbd-color: var(--title-color);
  --form-caption-mark-color: var(--title-color);
  --form-caption-bold-color: var(--title-color);

  --form-divider-text-color: var(--label-color);
  --form-divider-color: var(--divider-color);

  --form-link-text-color: #eee;
  --form-link-icon-color: #ccc;
  --form-link-underline-color: #555;
  --form-link-active-text-color: #fff;
  --form-link-active-icon-color: #fff;
  --form-link-active-underline-color: var(--link-underline-color);

  --form-group-background-color: #521d52;
  --form-group-border-color: #5f335f;

  --form-control-text-color: #fff;

  --form-control-label-color: #bd8ce2;
  --form-control-label-emphasized-color: #a079bd;

  --form-control-background-color: #722e73; /*#2b2b2b;*/
  --form-control-border-color: #904ca0;
  --form-control-active-background-color: #933f95; /*#522253;*/
  --form-control-active-border-color: #a458b6;
  --form-control-inactive-background-color: var(--form-group-background-color);

  --form-control-dropdown-header-background-color: #4c4c4c;

  --form-control-react-select-option-text-color: #fff;
  --form-control-react-select-option-background-color: #383838; /*#ccc;*/
  --form-control-react-select-option-border-color: #383838; /*#ccc;*/
  --form-control-react-select-option-focus-text-color: #fff;
  --form-control-react-select-option-focus-background-color: var(--form-control-active-background-color);
  --form-control-react-select-option-focus-border-color: #555; /*#ccc;*/
  --form-control-react-select-option-selected-text-color: #fff;
  --form-control-react-select-option-selected-background-color: #383838;
  --form-control-react-select-option-selected-border-color: #383838;
  --form-control-react-select-option-disabled-text-color: #444;
  --form-control-react-select-multivalue-text-color: #fff;
  --form-control-react-select-multivalue-background-color: #383838;
  --form-control-react-select-multivalue-border-color: #555;
  --form-control-react-select-multivalue-selected-background-color: #2f2f2f;
  --form-control-react-select-multivalue-disabled-text-color: white;
  --form-control-react-select-multivalue-disabled-background-color: var(--form-group-background-color);
  --form-control-react-select-multivalue-remove-text-color: #eee;
  --form-control-react-select-multivalue-remove-background-color: #383838;
  --form-control-react-select-multivalue-remove-border-color: #555;
  --form-control-react-select-multivalue-remove-hover-text-color: #888;
  --form-control-react-select-multivalue-remove-hover-background-color: #383838;
  --form-control-error-color: #e74c3c;
  --form-control-warning-color: #cf9944;

  --nav-text-color: #ccc;
  --nav-focus-color: white;

  --dropdown-menu-text-color: #ddd;
  --dropdown-menu-background-color: #111;
  --dropdown-menu-border-color: #333;
  --dropdown-item-selected-text-color: white;
  --dropdown-item-muted-text-color: #888;
  --dropdown-item-selected-background-color: #872205;
  --dropdown-divider-color: #333;

  --table-background-color: #521d52;
  --table-border-color: #331d5a;
  --table-header-text-color: #bbb;
  --table-header-background-color: #1f0b40;
  --table-header-border-color: #434343;
  --table-header-hilite-text-color: #fff;
  --table-header-hilite-background-color: #111;
  --table-row-odd-background-color: #521d52;
  --table-hilite-text-color: white;
  --table-hilite-background-color: rgb(63	14	64);
  --table-unread-text-color: #fff;
  --table-unread-selected-text-color: #fff;

  --widget-text-color: #aaa;
  --widget-header-title-color: #aaa;
  --widget-header-text-color: #888;
  --widget-footer-title-color: #888;
  --widget-footer-text-color: #888;
  --widget-metric-title-color: #fff;
  --widget-metric-text-color: #ddd;
  --widget-metric-footer-color: #888;
  --widget-background-color: #1F0B40;
  --widget-clickable-background-color: #383838;
  --widget-selected-background-color: #282828;
  --widget-border-color: #444;

  --tag-background-color: transparent;
  --tag-border-color: #484848;
  --tag-label-default-text-color: #d7ccc8;
  --tag-label-inactive-text-color: #999;
  --tag-label-todo-text-color: #b3befe;
  --tag-label-doing-text-color: #9FE2BF;
  --tag-label-done-text-color: #66CCFF;
  --tag-label-error-text-color: #ee8b84;
  --tag-label-warning-text-color: #d19c57;
  --tag-label-hilite-text-color: #ccc;

  --color-danger: #e55252;
  --color-warning: #fe8d59;
  --color-subtext: #888;

  --kbd-text-color: #ccc;
  --kbd-border-color: #555;

  --background-spinner-tiny: var(--form-background-color) url('/images/spinner-tiny-white.svg') center center no-repeat;
}

[data-theme="bluefrost"] {
  --theme: bluefrost;
  --mode: light;

  --text-color: #111;
  --title-color: #0187fc;
  --caption-color: #337faf;
  --label-color: #003557;
  --background-color: #ccc;

  --link-text-color: #111;
  --link-icon-color: #111;
  --link-underline-color: #3d96e0;
  --link-border-color: #8fc9f9;
  --link-active-text-color: #000;
  --link-active-icon-color: #000;
  --link-active-underline-color: #0077d5;

  --menu-text-color: #111;
  --menu-background-color:  #00acee;
  --menu-active-text-color: #000;
  --menu-border-color: #4a95db;
  --menu-title-color: #000;
  --menu-link-text-color: #111;
  --menu-link-background-color: #05acee;
  --menu-link-border-color: #00acee;
  --menu-link-underline-color: #00acee;
  --menu-link-active-text-color: #000;
  --menu-link-active-background-color: #05acee;
  --menu-link-active-border-color: #00acee;
  --menu-link-active-underline-color: var(--link-active-underline-color); /*#cecece; #872205;*/

  --button-text-color: #222;
  --button-title-color: #000;
  --button-caption-color: #555;
  --button-background-color: #cce8ff;
  --button-border-color: #67bafb;
  --button-active-text-color:  #000;
  --button-active-background-color: #f8f8f8;
  --button-active-border-color: #d5d5d5;
  --button-inactive-text-color: #4a4a4a;
  --button-inactive-background-color: #cacaca;
  --button-inactive-border-color: #959595;

  --primary-button-text-color: #fff;
  --primary-button-background-color: #0d6efd;
  --primary-button-border-color: #0d6efd;
  --primary-button-active-text-color: #fff;
  --primary-button-active-background-color: #0b5ed7;
  --primary-button-active-border-color: #0d6efd;

  --tertiary-button-text-color: #000;
  --tertiary-button-background-color: transparent;
  --tertiary-button-border-color: var(--form-background-color);
  --tertiary-button-active-text-color:  var(--button-active-text-color);
  --tertiary-button-active-background-color: var(--button-active-background-color);
  --tertiary-button-active-border-color: var(--form-background-color);

  --primary-blue-button-text-color: #ffffff;
  --primary-blue-button-background-color: #0d6efd;
  --primary-blue-button-border-color: #0d6efd;
  --primary-blue-button-active-text-color:  #ffffff;
  --primary-blue-button-active-background-color: #0b5ed7;
  --primary-blue-button-active-border-color: #0d6efd;

  --primary-violet-button-text-color: #ffffff;
  --primary-violet-button-background-color: #712cf9;
  --primary-violet-button-border-color: #712cf9;
  --primary-violet-button-active-text-color: #fffff;
  --primary-violet-button-active-background-color: #6528e0;
  --primary-violet-button-active-border-color: #712cf9;

  --form-header-text-color: #111;
  --form-header-background-color-rgb: 121, 194, 255;
  --form-header-background-color-alpha: 1;
  --form-header-border-color: #4a95db;
  --form-header-shadow: 0 4px 2px -2px #666;

  --form-header-toolbar-title-text-color: #000;

  --form-text-color: var(--text-color);
  --form-title-color: var(--title-color);
  --form-label-color: var(--label-color);
  --form-background-color: #00acee;
  --form-background-color-rgb: #00acee;
  --form-background-color-alpha: 1;
  --form-background-opacity: 100%;
  --form-border-color: #999;
  --form-border-shadow: rgba(63,63,63,0.48);

  --form-caption-color: var(--caption-color);
  --form-caption-kbd-color: var(--label-color);
  --form-caption-mark-color: var(--label-color);
  --form-caption-bold-color: var(--label-color);

  --form-divider-text-color: var(--label-color);
  --form-divider-color: var(--divider-color);

  --form-link-text-color: var(--link-text-color);
  --form-link-icon-color: var(--link-icon-color);
  --form-link-underline-color: var(--link-underline-color);
  --form-link-active-text-color: var(--link-active-text-color);
  --form-link-active-icon-color: var(--link-active-icon-color);
  --form-link-active-underline-color: var(--link-active-underline-color);

  --form-group-background-color: rgb(169,	210,	244);
  --form-group-border-color: #4da1c2;

  --form-control-text-color: #000;

  --form-control-label-color: #003557;
  --form-control-label-emphasized-color: #000;

  --form-control-background-color: #cce8ff;
  --form-control-border-color: #55bcff;
  --form-control-active-text-color: #000;
  --form-control-active-background-color: #f8f8f8;
  --form-control-active-border-color: #55bcff;
  --form-control-inactive-background-color: var(--form-group-background-color);

  --form-control-dropdown-header-background-color: #4c4c4c;

  --form-control-react-select-option-text-color: black;
  --form-control-react-select-option-background-color: #f8f8f8; /*#ccc;*/
  --form-control-react-select-option-border-color: transparent;
  --form-control-react-select-option-focus-text-color: #000;
  --form-control-react-select-option-focus-background-color: #eee;
  --form-control-react-select-option-focus-border-color: #ccc; /*#ccc;*/
  --form-control-react-select-option-selected-text-color: #000;
  --form-control-react-select-option-selected-background-color: #f8f8f8;
  --form-control-react-select-option-selected-border-color: #f8f8f8;
  --form-control-react-select-option-disabled-text-color: #ccc;
  --form-control-react-select-multivalue-text-color: black;
  --form-control-react-select-multivalue-background-color: #f8f8f8;
  --form-control-react-select-multivalue-border-color: #bbb;
  --form-control-react-select-multivalue-selected-background-color: #fff;
  --form-control-react-select-multivalue-disabled-text-color: #333;
  --form-control-react-select-multivalue-disabled-background-color: var(--form-group-background-color);
  --form-control-react-select-multivalue-remove-text-color: #222;
  --form-control-react-select-multivalue-remove-background-color: #f8f8f8;
  --form-control-react-select-multivalue-remove-border-color: #bbb;
  --form-control-react-select-multivalue-remove-hover-text-color: #000;
  --form-control-react-select-multivalue-remove-hover-background-color: #fff;
  --form-control-error-color: #e74c3c;
  --form-control-warning-color: #d18001;

  --nav-text-color: #ccc;
  --nav-focus-color: white;

  --table-background-color: #abd6fb;
  --table-border-color: #59b1fa;
  --table-header-text-color: #444;
  --table-header-background-color: #79c2ff;
  --table-header-border-color: #999;
  --table-header-hilite-text-color: #000;
  --table-header-hilite-background-color: #1dbfff;
  --table-row-odd-background-color: #abd6fb;
  --table-hilite-text-color: #000;
  --table-hilite-background-color: #79c2ff;
  --table-unread-text-color: #000;
  --table-unread-selected-text-color: #000;

  --widget-text-color: #222;
  --widget-header-text-color: #838383;
  --widget-footer-text-color: #737373;
  --widget-metric-title-color: #222;
  --widget-metric-text-color: #222;
  --widget-metric-footer-color: #555;
  --widget-background-color: #e0e0e0;
  --widget-clickable-background-color: #e3e3e3;
  --widget-selected-background-color: #ffffff;
  --widget-border-color: #bbb;

  --tag-background-color: transparent;
  --tag-border-color: #67bafb;
  --tag-label-default-text-color: #4d4d4d;
  --tag-label-inactive-text-color: #d7ccc8;
  --tag-label-todo-text-color: #3f54cf;
  --tag-label-doing-text-color: #0dbc5f;
  --tag-label-done-text-color:  #00aaff;
  --tag-label-error-text-color: #ee8b84;
  --tag-label-warning-text-color: #db9439;
  --tag-label-hilite-text-color: #000;

  --color-subtext: #888;
  --color-warning: #c86e05; /*#fe8d59;*/
  --color-danger: red;

  --kbd-text-color: #333;
  --kbd-border-color: #999;

  --background-spinner-tiny: rgb(var(--form-background-color), 1) url('/images/spinner-tiny-black.svg') center center no-repeat;
}

[data-theme="orangemocha"] {
  --theme: orangemocha;
  --mode: dark;

  --text-color: #111;
  --title-color: #0187fc;
  --caption-color: #faae96;
  --label-color: #ffc8b7;
  --background-color: #ccc;

  --link-text-color: #111;
  --link-icon-color: #111;
  --link-underline-color: #d18009;
  --link-border-color: #8fc9f9;
  --link-active-text-color: #000;
  --link-active-icon-color: #000;
  --link-active-underline-color: #da0f00;

  --menu-text-color: #ffc8b7;
  --menu-background-color: #ec6402;
  --menu-border-color: #68483c;
  --menu-active-text-color: #fff;
  --menu-title-color: #fff;
  --menu-link-text-color: var(--menu-text-color);
  --menu-link-background-color: var(--menu-background-color);
  --menu-link-border-color: var(--menu-background-color);
  --menu-link-underline-color: var(--menu-background-color);
  --menu-link-active-text-color: #fff;
  --menu-link-active-background-color: var(--menu-background-color);
  --menu-link-active-border-color: var(--menu-background-color);
  --menu-link-active-underline-color: var(--link-active-underline-color);

  --button-text-color: white;
  --button-title-color: white;
  --button-caption-color: white;
  --button-background-color: #444;
  --button-border-color: #555;
  --button-active-text-color: white;
  --button-active-background-color: #313131;
  --button-active-border-color: #666;
  --button-inactive-text-color: #b5b5b5;
  --button-inactive-background-color: #353535;
  --button-inactive-border-color: #454545;

  --primary-button-text-color: white;
  --primary-button-background-color: #952300;
  --primary-button-border-color: #b22a00;
  --primary-button-active-text-color: #fff;
  --primary-button-active-background-color: #521604;
  --primary-button-active-border-color: #ad4f34;

  --tertiary-button-text-color: white;
  --tertiary-button-background-color: transparent;
  --tertiary-button-border-color: var(--form-background-color);
  --tertiary-button-active-text-color: var(--button-active-text-color);
  --tertiary-button-active-background-color: var(--button-active-background-color);
  --tertiary-button-active-border-color: var(--form-background-color);

  --primary-blue-button-text-color: #ffffff;
  --primary-blue-button-background-color: #0d6efd;
  --primary-blue-button-border-color: #0d6efd;
  --primary-blue-button-active-text-color: #ffffff;
  --primary-blue-button-active-background-color: #0b5ed7;
  --primary-blue-button-active-border-color: #0d6efd;

  --primary-violet-button-text-color: #ffffff;
  --primary-violet-button-background-color: #712cf9;
  --primary-violet-button-border-color: #712cf9;
  --primary-violet-button-active-text-color: #fffff;
  --primary-violet-button-active-background-color: #6528e0;
  --primary-violet-button-active-border-color: #712cf9;

  --form-header-text-color: #ffb9a3;
  --form-header-background-color-rgb: 121, 85, 72;
  --form-header-background-color-alpha: 1;
  --form-header-border-color: #68483c;
  --form-header-shadow: 0 4px 2px -2px #111;

  --form-header-toolbar-title-text-color: #ffb9a3;

  --form-text-color: var(--text-color);
  --form-title-color: var(--title-color);
  --form-label-color: var(--label-color);
  --form-background-color: #B78C7B;
  --form-background-color-rgb: #B78C7B;
  --form-background-color-alpha: 1;
  --form-background-opacity: 100%;
  --form-border-color: #444;
  --form-border-shadow: rgba(0, 0, 0, 0.77);
  --form-small-text-color: #999;

  --form-caption-color: var(--caption-color);
  --form-caption-kbd-color: var(--label-color);
  --form-caption-mark-color: var(--label-color);
  --form-caption-bold-color: var(--label-color);

  --form-divider-text-color: #777;
  --form-divider-color: #a87867;

  --form-link-text-color: var(--link-text-color);
  --form-link-icon-color: var(--link-icon-color);
  --form-link-underline-color: var(--link-underline-color);
  --form-link-active-text-color: var(--link-active-text-color);
  --form-link-active-icon-color: var(--link-active-icon-color);
  --form-link-active-underline-color: var(--link-active-underline-color);

  --form-group-background-color: #9d6751;
  --form-group-border-color: #7a5448;

  --form-control-text-color: #fff;

  --form-control-label-color: #ffc8b7;
  --form-control-label-emphasized-color: #fff;

  --form-control-background-color: #271c17;
  --form-control-border-color: #87550c;
  --form-control-active-background-color: #7a3f24;
  --form-control-active-border-color: #d27f04;
  --form-control-inactive-background-color: var(--form-group-background-color);

  --form-control-dropdown-header-background-color: #4c4c4c;

  --form-control-react-select-option-text-color: #fff;
  --form-control-react-select-option-background-color: #383838; /*#ccc;*/
  --form-control-react-select-option-border-color: #383838; /*#ccc;*/
  --form-control-react-select-option-focus-text-color: #fff;
  --form-control-react-select-option-focus-background-color: var(--form-control-active-background-color);
  --form-control-react-select-option-focus-border-color: #555; /*#ccc;*/
  --form-control-react-select-option-selected-text-color: #fff;
  --form-control-react-select-option-selected-background-color: #383838;
  --form-control-react-select-option-selected-border-color: #383838;
  --form-control-react-select-option-disabled-text-color: #444;
  --form-control-react-select-multivalue-text-color: #fff;
  --form-control-react-select-multivalue-background-color: #383838;
  --form-control-react-select-multivalue-border-color: #555;
  --form-control-react-select-multivalue-selected-background-color: #2f2f2f;
  --form-control-react-select-multivalue-disabled-text-color: white;
  --form-control-react-select-multivalue-disabled-background-color: var(--form-group-background-color);
  --form-control-react-select-multivalue-remove-text-color: #eee;
  --form-control-react-select-multivalue-remove-background-color: #383838;
  --form-control-react-select-multivalue-remove-border-color: #555;
  --form-control-react-select-multivalue-remove-hover-text-color: #888;
  --form-control-react-select-multivalue-remove-hover-background-color: #383838;
  --form-control-error-color: #e74c3c;
  --form-control-warning-color: #cf9944;

  --nav-text-color: #ccc;
  --nav-focus-color: white;

  --dropdown-menu-text-color: #ddd;
  --dropdown-menu-background-color: #111;
  --dropdown-menu-border-color: #1a7bd5;
  --dropdown-item-selected-text-color: white;
  --dropdown-item-muted-text-color: #888;
  --dropdown-item-selected-background-color: #872205;
  --dropdown-divider-color: #1d92ff;

  --table-background-color: #795648;
  --table-border-color: #222;
  --table-header-text-color: #bbb;
  --table-header-background-color: #1b1b1b;
  --table-header-border-color: #434343;
  --table-header-hilite-text-color: #fff;
  --table-header-hilite-background-color: #111;
  --table-row-odd-background-color: #795648;
  --table-hilite-text-color: white;
  --table-hilite-background-color: #252525; /*#6f1e06;*/
  --table-unread-text-color: #fff;
  --table-unread-selected-text-color: #fff;

  --widget-text-color: #aaa;
  --widget-header-title-color: #aaa;
  --widget-header-text-color: #888;
  --widget-footer-title-color: #888;
  --widget-footer-text-color: #888;
  --widget-metric-title-color: #fff;
  --widget-metric-text-color: #ddd;
  --widget-metric-footer-color: #888;
  --widget-background-color: #2f2f2f;
  --widget-clickable-background-color: #383838;
  --widget-selected-background-color: #282828;
  --widget-border-color: #444;

  --tag-background-color: transparent;
  --tag-border-color: #484848;
  --tag-label-default-text-color: #d7ccc8;
  --tag-label-inactive-text-color: #999;
  --tag-label-todo-text-color: #b3befe;
  --tag-label-doing-text-color: #9FE2BF;
  --tag-label-done-text-color: #66CCFF;
  --tag-label-error-text-color: #ee8b84;
  --tag-label-warning-text-color: #d19c57;
  --tag-label-hilite-text-color: #ccc;

  --color-danger: #e55252;
  --color-warning: #fe8d59;
  --color-subtext: #888;

  --kbd-text-color: #ccc;
  --kbd-border-color: #555;

  --background-spinner-tiny: var(--form-background-color) url('/images/spinner-tiny-white.svg') center center no-repeat;
}

* {
  /* list-style: none; */
}
  
html {
  height: 100vh;
  background-color: var(--background-color) !important;
  scroll-behavior: smooth;
  color-scheme: dark light;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif; /*'Roboto', sans-serif; */
  font-weight: var(--main-text-weight);
  -webkit-font-smoothing: antialiased;
}

.nav-link.active {
  color: white !important;
  border-bottom: 1px solid var(--form-link-active-underline-color);
}

.navbar-brand {
  font-weight: 500;
  margin-right: 0 !important;
}

/* main --- */

main {
  height: 100%;
  padding: 0;
}

main .navbar {
  height: 56px;
  background-color: rgb(34, 34, 34)  !important; /*black !important;*/ /* rgb(36  35  35) !important; */
  /*margin-left: var(--sidebar-width);*/
  /* transition: all 0.2s ease; */
  padding: 0rem 1rem;
  border-bottom: 1px solid #444;
}

/* main-menu */

.main-menu {
  position: fixed;
  top: 0;
  height: var(--main-menu-height);
  min-height: var(--main-menu-height);
  width: 100%;
  padding: 0 12px 0 12px;
  margin: 0;
  background-color: var(--menu-background-color);
  border-bottom: 1px solid var(--menu-border-color)
}


.main-menu .items {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 11pt;
  font-weight: var(--main-menu-text-weight);
  padding: 8px 0 8px 0;
  margin: 0 0;
  gap: 12px;
}

.main-menu ul.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
}

.main-menu li.brand-item {
  list-style: none;
  padding: 0;
  white-space: nowrap;
}

.main-menu li.brand-item > a  {
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 0 !important;
  opacity: 0.6;
  outline: 0 !important;
}

.main-menu li.brand-item > a.brand-link-active  {
  text-decoration: none;
  border: 0 !important;
  opacity: 1;
}

.main-menu li.brand-item > a:hover {
  text-decoration: none;
  border: 0 !important;
  opacity: 1;
}

.main-menu li.brand-item > a:focus {
  text-decoration: none;
  border: 0 !important;
  opacity: 1;
}


.main-menu li.brand-item img {
  /*width: auto;*/
  /*height: 14px;*/
  padding: 0;
}

.main-menu li.brand-item sup {
  font-size: 50%;
}

.main-menu li.menu-item {
  list-style: none;
  display: inline-block;
  padding: 0;
}

.main-menu li.menu-item.help-item {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin-left: auto;
}

.main-menu li.menu-item.user-item {
  list-style: none;
  display: inline-block;
  padding: 0;
}

.main-menu li.menu-item.tools-item {
  list-style: none;
  display: inline-block;
  padding: 0;
}

.main-menu li.menu-item > a {
  color: var(--menu-text-color);
  font-weight: var(--main-text-weight);
  text-decoration: none;
  outline: 0 !important;
}

.main-menu li.menu-item > a:hover {
  color: var(--menu-active-text-color);
  font-weight: var(--main-active-text-weight);
  text-decoration: underline !important;
  text-decoration-thickness: var(--menu-underscore-thickness) !important;
  text-decoration-color: var(--menu-link-active-underline-color) !important;
  text-underline-offset: var(--menu-underscore-offset) !important;
}

.main-menu li.menu-item > a:focus-visible {
  color: var(--menu-active-text-color);
}

.main-menu li.menu-item > a.active {
  color: var(--menu-active-text-color);
  font-weight: var(--main-active-text-weight);
  text-decoration: underline !important;
  text-decoration-thickness: var(--menu-underscore-thickness) !important;
  text-decoration-color: var(--menu-link-active-underline-color) !important;
  text-underline-offset: var(--menu-underscore-offset) !important;
}

.main-menu .round-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid var(--menu-text-color);
  border-radius: 50%;
  font-weight: 700;
}

.main-menu .round-item:hover {
  border: 1px solid var(--link-underline-color);
}

.main-menu .user {
  margin-left: auto;
  padding-right: 10px;
}

.main-menu .user ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.main-menu .user img {
  width: auto;
  /* max-width: 100px; */
  max-height: 26px;
  border: 2px solid #888;
  border-radius: 50%;
}

.main-menu .user a:hover img {
  width: auto;
  max-height: 26px;
  border: 2px solid white;
  border-radius: 50%;
}

/*a.form-title-link  {*/
/*  color: var(--form-title-link-text-color);*/
/*  text-decoration: none;*/
/*  border-bottom: 2px solid transparent;*/
/*}*/

/*a.form-title-link:hover {*/
/*  color: var(--form-title-link-hilite-text-color);*/
/*  text-decoration: none;*/
/*  border-bottom: 2px solid var(--form-title-link-hilite-border-color);*/
/*}*/

/*a.form-title-link.active {*/
/*  color: var(--form-title-link-hilite-text-color);*/
/*  text-decoration: none;*/
/*  border-bottom: 2px solid var(--form-title-link-hilite-border-color);*/
/*}*/

ul.nav li.nav-link label {
  font-size: var(--form-control-font-size);
  font-weight: 700;
  text-transform: uppercase;
  color: #999;
  padding: 0 20px 0 0 !important;
  margin: 0 !important;
  border-right: 1px solid #444;
}

ul.nav li.nav-link a {
  color: var(--nav-text-color);
  padding: 0 !important;
  margin: 0 20px 0 0 !important;
}

ul.nav li.nav-link a:hover {
  color: var(--nav-focus-color);
}

ul.nav li.nav-link a:focus {
  color: var(--nav-focus-color);
  border-bottom: 2px solid var(--form-link-active-underline-color);
}

ul.nav li.nav-link a.active {
  color: var(--nav-focus-color);  
  border-bottom: 2px solid var(--form-link-active-underline-color);
}


.upload-link > label {
  cursor: pointer;
}

.upload-link > label:hover {
  /*background-color:*/
}

input.upload + label {
  cursor: pointer;
  border: 1px solid transparent;
  background-color: var(--form-control-background-color);
}

.form-group input.upload + label:hover {
  opacity: 0.8;
}

.form-group input[disabled].upload + label {
  cursor: default;
}

.form-group input[disabled].upload + label:hover {
  opacity: 1;
}

.form-group .upload:focus + label,
.form-group .upload + label:hover {
  border: 1px solid rgb(62	90	124)
}


/* miscellaneous */

.form-group {
  text-align: left;
  background-color: var(--form-group-background-color);
  padding: var(--form-group-padding);
  border: 1px solid var(--form-group-border-color);
  border-radius: 5px;
  margin-top: 0;
  margin-bottom: 10px !important;
}

.form-group.row .col-form-label {
  font-size: var(--form-control-font-size) !important;
  color: var(--form-control-label-color) !important;
  text-align: right !important;
  padding-top: 0;
}

.form-group .col-form-label {
  display: inline;
  font-size: var(--form-control-font-size) !important;
  color: var(--form-control-label-color) !important;
  line-height: 2.4;
  text-align: left;
}

.form-group .col-form-label.label-value {
  color: var(--form-control-text-color) !important;
}

/*.col-form-label:after {*/
/*  content: ": "*/
/*}*/

ul.colored-list {
  list-style: none;
  padding-left: 15px;
}

ul.colored-list li {
  display: flex !important;
  padding: 3px 0 3px 0;
}

ul.colored-list li::before {
  content: "\2022";  
  color: #888; 
  font-weight: 400; 
  display: inline-block;
  width: 1em;
  margin-left: -1em; 
}


.form-card {
  /* color: var(--form-text-color);
  background-color: var(--form-background-color);
  padding: 20px;
  margin: 0px 0px 20px 0px;
  border: 1px solid var(--form-border-color); */
  /* border-radius: 3px; */
}

.section-divider {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--label-color);
  padding-bottom: 10px;
  border-bottom: 1px solid var(--form-divider-color) !important;
  margin: 10px 0 20px 0;
}

.divider-line {
  border-top: 1px solid var(--form-divider-color) !important;
  margin: 10px 0 10px 0;
  padding-top: 20px;
}

.divider-text {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--form-divider-text-color);
  margin-top: 8px;
}

.form-card .divider {
  border: 1px solid var(--form-divider-color);
  height: 1px;
  margin: 26px 10px 26px 10px;
}

.form-card small {
  color: var(--form-caption-color);
}

.form-card small em {
  color: #ccc;
  font-weight: 400;
  font-style: normal;
} 

.form-card small.form-msg {
  display: block;
  color: white;
  background-color: rgb(253 71 91);
  font-style: normal;
  padding: 4px;  
}

.form-card .btn-outline-secondary {
  color: #ffff;
  background-color: #777;
}

/*.form-card a {*/
/*  color: var(--form-link-text-color);*/
/*  text-decoration: none;*/
/*  border-bottom: 1px solid var(--form-link-border-color);*/
/*  cursor: pointer;*/
/*}*/

/*.form-card a:hover {*/
/*  color: var(--form-link-hilite-text-color);*/
/*  text-decoration: none;*/
/*  border-bottom: 1px solid var(--form-link-hilite-border-color);;*/
/*}*/

/*.form-group:not(:first-child) {*/
/*  margin-top: 20px !important;*/
/*}*/

.form-group.form-compact {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/*.form-group:nth-child(n+2) {*/
/*  margin-top: 20px !important;*/
/*}*/

.form-group label.text {
  color: var(--form-control-text-color);
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
}

.form-group .description {
  display: block;
  color: #999;
  /* font-style: italic; */
}

.form-group .error {
  display: none;
  color: red;
  /* font-style: italic; */
}

.form-group.invalid .description {
  display: none !important;
}

.form-group.invalid .error {
  display: block !important;
  color: red !important;
}

.form-group:invalid .form-control{
  border: 1px solid red !important;
}

.form-group.invalid #fg_captcha iframe {
  border: 1px solid red !important;
}

#fg_captcha {
  overflow: hidden;
  width: 302px;
  height: 76px;
  border-radius: 3px;
}

/* .form-control:disabled, .form-control[readonly] {
  background-color: var(--form-control-disabled-background-color) !important;
  opacity: 1;
}
 */
.form-group:invalid .description {
  display: block;
  color:red;
}

.form-control {
  min-height: var(--form-control-height);
}

input[type=text].form-control {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color);
  background-color: var(--form-control-background-color);
  border: 1px solid var(--form-control-border-color);
}

input[type=text]:focus.form-control  {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color);
  background-color: var(--form-control-active-background-color);
  box-shadow: 0 0 0 1px var(--form-control-active-border-color) !important;
}

input[type=text]:not(:disabled):hover.form-control  {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color);
  background-color: var(--form-control-active-background-color);
  border-color: var(--form-control-border-color);
}

input[type=text]:disabled.form-control  {
  color: var(--form-control-text-color);
  background-color: var(--form-control-inactive-background-color);
}

input[type=number].form-control {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color);
  background-color: var(--form-control-background-color);
  border: 1px solid var(--form-control-border-color);
}

input[type=number]:focus.form-control  {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color);
  background-color: var(--form-control-background-color);
}

input[type=number]:disabled.form-control  {
  color: var(--form-control-text-color);
  background-color: var(--form-control-inactive-background-color);
}

input[type=email].form-control {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color);
  background-color: var(--form-control-background-color);
  border: 1px solid var(--form-control-border-color);
}

input[type=email]:focus.form-control  {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color);
  background-color: var(--form-control-active-background-color);
  box-shadow: 0 0 0 1px var(--form-control-active-border-color) !important;
}

input[type=email]:not(:disabled):hover.form-control  {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color);
  background-color: var(--form-control-active-background-color);
  border-shadow: none !important;
}

input[type=email]:disabled.form-control  {
  color: var(--form-control-text-color);
  background-color: var(--form-control-inactive-background-color);
}

input[type=password].form-control {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color);
  background-color: var(--form-control-background-color);
  border: 1px solid var(--form-control-border-color);
}

input[type=password]:focus.form-control  {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color);
  background-color: var(--form-control-background-color);
}

input[type=password]:disabled.form-control {
  color: var(--form-control-text-color);
  background-color: var(--form-control-inactive-background-color);
}

input[type=color].form-control {
  padding: 0;
  background-color: transparent;
}

/*.form-group input[type=text].form-control {*/
/*  font-size: var(--form-control-font-size);*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-background-color);*/
/*  border: 1px solid var(--form-control-border-color);*/
/*}*/

/*.form-group input[type=text]:focus.form-control  {*/
/*  font-size: var(--form-control-font-size);*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-background-color); */
/*}*/

/*.form-group input[type=text]:disabled.form-control  {*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-disabled-background-color);*/
/*}*/

/*.form-group input[type=number].form-control {*/
/*  font-size: var(--form-control-font-size);*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-background-color);*/
/*  border: 1px solid var(--form-control-border-color);*/
/*}*/

/*.form-group input[type=number]:focus.form-control  {*/
/*  font-size: var(--form-control-font-size);*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-background-color);*/
/*}*/

/*.form-group input[type=number]:disabled.form-control  {*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-disabled-background-color);*/
/*}*/

/*.form-group input[type=email].form-control {*/
/*  font-size: var(--form-control-font-size);*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-background-color);*/
/*  border: 1px solid var(--form-control-border-color);*/
/*}*/

/*.form-group input[type=email]:focus.form-control  {*/
/*  font-size: var(--form-control-font-size);*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-background-color); */
/*}*/

/*.form-group input[type=email]:disabled.form-control {*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-disabled-background-color);*/
/*}*/

/*.form-group input[type=password].form-control {*/
/*  font-size: var(--form-control-font-size);*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-background-color); */
/*  border: 1px solid var(--form-control-border-color);*/
/*}*/

/*.form-group input[type=password]:focus.form-control  {*/
/*  font-size: var(--form-control-font-size);*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-background-color); */
/*}*/

/*.form-group input[type=password]:disabled.form-control {*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-disabled-background-color);*/
/*}*/

/*.form-group input[type=color] {*/
/*  padding: 0;*/
/*  background-color: transparent;*/
/*}*/


/* read-only */

.form-group textarea.form-control  {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-background-color) !important;
  border: 1px solid var(--form-control-border-color);
}

.form-group textarea:focus.form-control  {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-active-background-color) !important;
}

.form-group textarea:not(:disabled):hover.form-control  {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-active-background-color) !important;
}

.form-group textarea:disabled.form-control  {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-inactive-background-color) !important;
}

.form-group select {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color) !important; 
  background-color: var(--form-control-background-color) !important;
  border: 1px solid var(--form-control-border-color) !important;
}

.form-group select:disabled {
  color: var(--form-control-text-color) !important; 
  background-color: var(--form-control-inactive-background-color) !important;
}

.form-group input.upload {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.form-group input.upload + label {
  cursor: pointer;
  border: 1px solid transparent;
  background-color: var(--form-control-background-color);
}

.form-group input.upload + label:hover {
  opacity: 0.8;
}

.form-group input[disabled].upload + label {
  cursor: default;
}

.form-group input[disabled].upload + label:hover {
  opacity: 1;
}

.form-group .upload:focus + label,
.form-group .upload + label:hover {
    border: 1px solid rgb(62	90	124)
}

.form-group .form-check-label {
  font-size: var(--form-control-font-size);
  color: var(--form-control-label-color);
}

.form-group .form-check-inline .form-check-label {
  margin-left: 8px;
}

.form-group.invalid label {
  color: var(--form-control-error-color) !important;
}

.form-group.invalid input[type="text"].form-control {
  border: 1px solid var(--form-control-error-color) !important;
}

.form-group.invalid input[type="email"].form-control {
  border: 1px solid var(--form-control-error-color) !important;
}

.form-group.invalid textarea.form-control {
  border: 1px solid var(--form-control-error-color) !important;
}

.form-group.invalid input[type="number"].form-control {
  border: 1px solid var(--form-control-error-color) !important;
}

.form-group.invalid .description {
  display: block;
  color: var(--form-control-error-color);
}

.form-group.warning label {
  color: var(--form-control-warning-color) !important;
}

label.form-control {
  display: flex;
  align-items: center;
  width: fit-content;
  height: auto; /*calc(1.5em + 0.75rem + 2px);*/
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color);
  background-color: transparent !important;
  border: 1px solid var(--form-control-border-color);
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: var(--form-control-border-radius);
  margin-bottom: 0 !important
}

label.btn {
  margin: 0 !important;
}

.account-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.react-select__control {
  font-size: var(--form-control-font-size) !important;
  color: var(--form-control-text-color) !important; 
  background-color: var(--form-control-background-color) !important;
  border: 1px solid var(--form-control-border-color) !important;
  border-radius: 5px !important;
  padding: 0 !important;
  min-height: 0 !important;
}

.react-select__control:hover {
  background-color: var(--form-control-active-background-color) !important;
  border-color: var(--form-control-border-color) !important;
}

 .react-select__control--menu-is-open {
   background-color: var(--form-control-active-background-color) !important;
}

.react-select__control--menu-is-focused {
  background-color: var(--form-control-active-background-color) !important;
}


.react-select__menu {
  position: relative !important;
  padding: 6px !important;
  text-align: left !important;
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75) !important;
}

.react-select.react-select__absolute > .react-select__menu {
  position: absolute !important;
  padding: 6px !important;
  text-align: left !important;
}

.react-select.react-select--is-disabled .react-select__value-container {
  background-color: var(--form-control-inactive-background-color) !important;
  border-radius: 3px;
}

.react-select.react-select--is-disabled .react-select__indicators {
  display: none;
}

.react-select__input input {
  font-size: var(--form-control-font-size);
  color: var(--form-control-text-color) !important; 
  background-color: var(--form-control-background-color) !important;
  width: 100% !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.react-select__input input:focus {
  background-color: var(--form-control-active-background-color) !important;
}

.react-select__input input:hover {
  background-color: var(--form-control-active-background-color) !important;
}

.react-select__control:hover .react-select__input input {
  background-color: var(--form-control-active-background-color) !important;
}

.react-select__control--menu-is-open .react-select__input input {
  background-color: var(--form-control-active-background-color) !important;
}

.react-select__single-value {
  color: var(--form-control-text-color) !important;
  height: 22px;
}

.react-select__option {
  border-color: transparent !important;
}

.react-select__option small {
  color: var(--form-caption-color);
}

/*.react-select__option--is-disabled {*/
/*  color: var(--form-control-inactive-background-color) !important;*/
/*}*/

.react-select__multi-value {
  background-color: var(--form-control-background-color) !important;
}

.react-select__multi-value__label {
  font-size: 100% !important;
  color: var(--form-control-react-select-multivalue-text-color) !important;
  background-color: var(--form-control-background-color) !important;
  border-top: 1px solid var(--form-control-react-select-multivalue-border-color) !important;
  border-right: 0 !important;;
  border-bottom: 1px solid var(--form-control-react-select-multivalue-border-color) !important;
  border-left: 1px solid var(--form-control-react-select-multivalue-border-color) !important;
  border-radius: 0 !important;
}

.react-select__control:hover .react-select__multi-value__label {
  background-color: var(--form-control-react-select-multivalue-selected-background-color) !important;
}

.react-select__control--menu-is-open .react-select__multi-value__label {
  background-color: var(--form-control-react-select-multivalue-selected-background-color) !important;
}

.react-select__multi-value__label:hover {
  background-color: var(--form-control-react-select-multivalue-selected-background-color) !important;
}

.react-select__control .react-select__multi-value__label input:focus {
  background-color: var(--form-control-react-select-multivalue-selected-background-color) !important;
}

.react-select__control .react-select__multi-value__label input:hover {
  background-color: var(--form-control-react-select-multivalue-selected-background-color) !important;
}

.react-select__multi-value__remove {
  color: var(--form-control-react-select-multivalue-remove-text-color) !important;
  background-color: var(--form-control-background-color) !important;
  border-top: 1px solid var(--form-control-react-select-multivalue-remove-border-color) !important;
  border-right: 1px solid var(--form-control-react-select-multivalue-remove-border-color) !important;
  border-bottom: 1px solid var(--form-control-react-select-multivalue-remove-border-color) !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  cursor: pointer;
}

.react-select__control:hover .react-select__multi-value__remove {
  background-color: var(--form-control-react-select-multivalue-selected-background-color) !important;
}

.react-select__control--menu-is-open .react-select__multi-value {
  background-color: var(--form-control-react-select-multivalue-selected-background-color) !important;
}

.react-select__control--menu-is-open .react-select__multi-value__remove {
  background-color: var(--form-control-react-select-multivalue-selected-background-color) !important;
}

.react-select__multi-value__remove:hover {
  color: var(--form-control-react-select-multivalue-remove-hover-text-color) !important;
  background-color: var(--form-control-react-select-multivalue-remove-hover-background-color) !important;
  border-top: 1px solid var(--form-control-react-select-multivalue-remove-border-color) !important;  
  border-right: 1px solid var(--form-control-react-select-multivalue-remove-border-color) !important;  
  border-bottom: 1px solid var(--form-control-react-select-multivalue-remove-border-color) !important;
  border-left: 0 !important;  
}

.react-select__control.react-select__control--is-disabled .react-select__multi-value__label {
  color: var(--form-control-react-select-multivalue-disabled-text-color) !important;
  background-color: var(--form-control-react-select-multivalue-disabled-background-color) !important;
  border-top: 1px solid var(--form-control-react-select-multivalue-remove-border-color) !important;
  border-right: 1px solid var(--form-control-react-select-multivalue-remove-border-color) !important;
  border-bottom: 1px solid var(--form-control-react-select-multivalue-remove-border-color) !important;
  border-left: 1px solid var(--form-control-react-select-multivalue-remove-border-color) !important;
}

.react-select__control--is-disabled .react-select__multi-value__remove {
  display: none;
}

.react-select__indicator-separator {
  background-color: var(--form-control-background-color) !important;
}

.react-select__menu {
  color: var(--form-control-text-color) !important; 
  background-color: var(--form-control-background-color) !important;
}

.react-select__option {
  border: 1px solid var(--form-control-react-select-option-border-color);
  white-space: pre-line !important;
}

.react-select__option.react-select__option--is-focused {
  color: var(--form-control-react-select-option-focus-text-color) !important;
  background-color: var(--form-control-react-select-option-focus-background-color) !important;
  border: 1px solid var(--form-control-react-select-option-focus-border-color);
}

.react-select__option.react-select__option--is-selected {
  font-weight: 400;
  color: var(--form-control-react-select-option-text-color) !important;
  background-color: var(--form-control-react-select-option-background-color) !important;
  border: 1px solid var(--form-control-react-select-option-focus-border-color);
}

.form-group.invalid .react-select__control {
  border: 1px solid var(--form-control-error-color) !important;
}


/* react-datepicker styles */

.react-datepicker-wrapper {
  /* width: 200px; */
}

.react-datepicker__input-container input {
  font-family: inherit !important;
  font-size: var(--form-control-font-size) !important;
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-background-color) !important;  
  border: 1px solid var(--form-control-border-color) !important;
  height: 38px;
  padding: 2px 8px;
  border-radius: var(--form-control-border-radius);
  width: 100%;
}

.react-datepicker__input-container input[disabled] {
  background-color: var(--form-control-inactive-background-color) !important;
}


.react-datepicker-ignore-onclickoutside {
  font-family: inherit !important;
  font-size: inherit !important;
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-background-color) !important;
  border: 1px solid var(--form-control-border-color) !important;
  height: 36px;
  padding: 2px 8px;
  border-radius: var(--form-control-border-radius);
}

.react-datepicker {
  font-family: inherit !important;
  font-size: inherit !important;
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-background-color) !important;
  border: 1px solid var(--form-control-border-color) !important;
}

.react-datepicker__time {
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-background-color) !important;
}

.react-datepicker__header {
  /* color: #ccc !important; */
  /*background-color: #999 !important;*/
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-dropdown-header-background-color) !important;
  border: 1px solid var(--form-control-border-color) !important;
}

.react-datepicker-time__header {
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-dropdown-header-background-color) !important;
}

.react-datepicker__day-name, 
.react-datepicker__day, 
.react-datepicker__time-name {
  color: var(--form-control-text-color) !important;
}

.react-datepicker__current-month {
  color: var(--form-control-text-color) !important;
}

.react-datepicker__time-container {
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-background-color) !important;
  border-left: 1px solid var(--form-control-border-color) !important;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__year-text--in-range {
  background-color: black !important;
  color: #000;
}

/* react-numeric-input */

.react-numeric-input input {
  color: var(--form-control-text-color) !important;
  background-color: var(--form-control-background-color) !important;
  border: 1px solid var(--form-control-border-color) !important;
}

.react-numeric-input input:disabled {
  background-color: var(--form-control-inactive-background-color) !important;
}

.react-numeric-input input:not(:disabled):hover {
  background-color: var(--form-control-active-background-color) !important;
  border-color: var(--form-control-border-color) !important;
}

.react-numeric-input input:not(:disabled):focus {
  background-color: var(--form-control-active-background-color) !important;
  border-color: var(--form-control-border-color) !important;
}

.react-numeric-input b {
  display: none !important;
  font-size: var(--form-control-font-size) !important;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.input-group-text {
  color: var(--form-control-label-color) !important;
  background-color: rgb(var(--form-background-color-rgb), var(--form-header-background-color-alpha)); /*var(--form-background-color);*/
  border: none !important;
}

.react-numeric-input b i {
  border-color: #ccc transparent #ccc !important;
}

.input-group-nowrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

#inp_targetBudget::after {
  content: "USD"
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px var(--form-control-background-color) inset !important;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--form-control-border-color) !important;;
  -webkit-text-fill-color: var(--form-control-text-color) !important;
  -webkit-box-shadow: 0 0 0px 1000px var(--form-control-background-color) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.nav-pills .nav-link {
  border-bottom: 1px solid #555;
  border-radius: 0 !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: transparent;
  border-bottom: 1px solid #999;
  border-radius: 0 !important;
}

.nav-link.disabled {
  color: #777 !important;
}

#overlay {
  display:flex;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black url('/images/spinner-white.svg') center center no-repeat;
  opacity: .6;
  align-items: center;
  justify-content: center;
}

#overlay p {
  font-size: 20px;
  padding-top: 158px;
}

#overlay2 {
  display: flex;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: .6;
  align-items: center;
  justify-content: center;
}

.report-boxes {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.report-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 110px;
  padding: 12px;
  background-color: var(--widget-background-color); /*#2f2f2f;*/
  border: 1px solid var(--widget-border-color); /*#444;*/
  border-radius: var(--form-control-border-radius);
  margin: 0.15rem;
}

.report-box.centered {
  align-items: center;
}

.report-box h3 {
  color: var(--widget-header-text-color); /*#aaa;*/
  font-size: 20px;
  margin: 0;
}

.report-box h4 {
  color: var(--widget-header-text-color); /*#aaa;*/
  font-size: 11px;
  margin: 0;
}

.report-box p {
  color: var(--widget-metric-text-color); /*white*/
  font-size: 20px;
  margin: 0;
}

.report-box.clickable {
  cursor: pointer;
}

.report-box.clickable:hover {
  background-color: var(--widget-selected-background-color) !important;
}

.report-box.clickable p {
  color: var(--widget-metric-text-color) !important;
  font-size: 20px;
  margin: 0;
}

.report-box .mini-chart {
  position: relative;
  width: 60px;
  height: 44px; 
  margin: 0 0.5rem 0 0;
  border: 0;
}

.report-box footer {
  color: var(--widget-footer-text-color); /*#aaa;*/
  font-size: 9px;
  margin: 8px 0 0 0;
}


.live-preview-section-divider {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--form-divider-text-color);
  margin: 0px 0 16px 0; 
}

.live-preview-section-divider-spinner {
  width: 20px;
  height: 20px;
  background: var(--background-spinner-tiny);
}


.live-preview-container {
 position: relative;
 box-sizing: border-box;
 font-family: "Arial", "sans-serif", "serif";
 width: 300px;
 min-width: 300px;
 max-width: 300px;
 height: 250px;
 min-height: 250px; 
 max-height: 250px;
 color: black;
 background-color: white;
 overflow: hidden;
 border-left:  4px solid red; 
 border-right: 4px solid red; 
}

.live-preview-container .header {
 position: absolute;
 top: 0;
 left: 0;
 width: 292px;
 height: 30px;
 font-size: 24px;
 font-weight: 700;
 line-height: 30px;
 text-transform: uppercase;
 background-color: red;
 color: white;
}

.live-preview-container .title {
 position: absolute;
 top: 32px;
 left: 0; 
 width: 292px;
 max-width: 292px;
 min-width: 292px;        
 font-size: 18px;
 line-height: 18px;
 font-weight: 700;
 text-transform: uppercase;
 padding: 6px 2px 4px 0px;
 line-height: 14px;
 white-space: nowrap;
}

.live-preview-container .title center {
 width: 292px;
 max-width: 292px;
 min-width: 292px;
 white-space: nowrap;
}

.live-preview-container .lea {
  display: flex;
  align-items: center;
  position: absolute;
  top: 57px;
  left: 125px; 
  width: 108px;
  max-width: 108px;
  min-width: 108px;
  height: 60px;
  min-height: 60px;        
  max-height: 60px;        
  font-size: 11px;
  font-weight: 400;
  color: red;
  line-height: 16px;
  white-space: pre-wrap;
  overflow: hidden; 
}

.live-preview-container .lea-logo {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 58px;
  left: 237px; 
  max-width: 58px;
  max-height: 58px;
}

.live-preview-container .lea-logo img {
  max-width: 58px;
  max-height: 58px;
}

.live-preview-container .photo {
  position: absolute;
  top: 59px;
  left: 1px;   
  width: 120px;
  height: 160px;
  background-color: #efefef;
}

.live-preview-container .photo img {
 width: 120px;
 height: 160px;
 object-fit: contain;
}

.live-preview-container .photo-custom {
  position: absolute;
  top: 59px;
  left: 1px;   
  width: 300px;
  height: 250px;
  background-color: #efefef;
}

.live-preview-container .photo-custom img {
 width: 300px;
 height: 250px;
 object-fit: contain;
}

.live-preview-container .content {
 position: absolute; 
 top: 116px;
 left: 125px;   
 width: 168px;
 max-width: 168px;
 height: 99px;
 max-height: 99px;
 font-size: 12px;
 padding: 0;
 margin: 2px 0 4px 0;
 text-overflow: ellipsis;
}

.live-preview-container .content p {
 padding: 0;
 margin: 0 0 8px 0;
}

.live-preview-container .footer {
 position: absolute; 
 top: 220px;
 left: 0;   
 width: 294px;
 height: 30px;  
 font-size: 16px;
 color: white;
 padding: 3px 0 0 0;
 min-height: 30px;
 background-color: red;
}

.solv-page-preview-container {
  overflow: auto;
  min-height: 200px;
  max-height: 800px;
  padding-top: 0;
  background-color: white;
  border: 1px solid var(--form-border-color);
}

.external-page-preview-container {
  min-height: 100px;
}

.iframe-embed {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
}
.iframe-embed-wrapper {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    width: 90%;
}
.iframe-embed-responsive-16by9 {
    padding-bottom: 56.25%;
}


/* Ad Creative */

.adcreative-container {
  position: relative;
  box-sizing: border-box;
  font-family: "Arial", "sans-serif", "serif";
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  height: 250px;
  min-height: 250px;
  max-height: 250px;
  color: black;
  background-color: white;
  overflow: hidden;
  border:  4px solid red;
}

.adcreative-container .header {
  position: absolute;
  top: 0px;
  left: 0;
  font-size: 20px;
  font-weight: 700;
  width: 292px;
  height: 29px;
  line-height: 26px;
  text-transform: uppercase;
  background-color: red;
  color: white;
  white-space: nowrap;
}

.adcreative-container .title {
  position: absolute;
  top: 29px;
  left: 0;
  width: 292px;
  max-width: 292px;
  min-width: 292px;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  text-transform: uppercase;
  color: black;
  background-color: white;
  white-space: nowrap;
  border-bottom: 3px solid red;
}

.adcreative-container .title center {
  width: 292px;
  max-width: 292px;
  min-width: 292px;
}

.adcreative-container .tenant-info {
  display: flex;
  align-items: center;
  position: absolute;
  top: 64px;
  left: 132px;
  width: 156px;
  height: 32px;
}

.adcreative-container .tenant-info .tenant-name {
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  max-height: 32px;
  /*color: black;*/
  overflow: hidden;
}

.adcreative-container .tenant-info .tenant-logo {
  max-width: 32px;
  max-height: 32px;
  z-index: 99;
  object-fit: contain;
  margin-right: 4px;
}

.adcreative-container .tenant-logo img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.adcreative-container .photo {
  position: absolute;
  top: 58px;
  left: 0px;
  width: 124px;
  height: 164px;
}

.adcreative-container .photo img {
  width: 124px;
  height: 164px;
  object-fit: contain;
}

.adcreative-container .photo-custom {
  background-color: #efefef;
}

.adcreative-container .photo-custom img {
  width: 300px;
  height: 250px;
  object-fit: contain;
}

.adcreative-container .content {
  position: absolute;
  top: 100px;
  left: 132px;
  width: 156px;
  max-width: 156px;
  height: 120px;
  max-height: 120px;
  font-size: 10px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: white;
  text-align: left;
}

.adcreative-container .content.no-tenant-info {
  top: calc(100px - 32px) !important;
  height: calc(120px + 32px) !important;
  max-height: calc(120px + 32px) !important;
}

.adcreative-container .content h1, h2, h3, h4, h5, h6 {
  font-weight: 700 !important;
  margin: 0 0 6px 0 !important;
}

.adcreative-container .content h1 {
  font-size: 18px;
}
.adcreative-container .content h2 {
  font-size: 16px;
}
.adcreative-container .content h3 {
  font-size: 14px;
}
.adcreative-container .content h4 {
  font-size: 12px;
}
.adcreative-container .content h5 {
  font-size: 10px;
}
.adcreative-container .content h6 {
  font-size: 10px;
}

.adcreative-container .content p {
  padding: 0;
  margin: 0 0 8px 0;
}
.adcreative-container .content a {
  color: #58a6ff;
  text-decoration: none;
  border-bottom: 0;
}
.adcreative-container .content a:hover {
  text-decoration: underline;
  border-bottom: 0;
}

.adcreative-container .content table {
  width: 96%;
  margin-bottom: 16px;
  border: 0;
  border-top: 1px solid rgba(0,0,0,0.5);
  border-bottom: 1px solid rgba(0,0,0,0.5);
  border-spacing: 0;
}
.adcreative-container .content table th {
  border-left: 1px solid rgba(0,0,0,0.5);
  background: rgba(0,0,0,0.1);
  padding: 6px;
}
.adcreative-container .content table td {
  border-left: 1px solid rgba(0,0,0,0.5);
  border-top: 1px solid rgba(0,0,0,0.5);
  padding: 6px;
}
.adcreative-container .content table tbody tr:first-child td {
  border-left: 1px solid rgba(0,0,0,0.5);
  border-top: 1px solid rgba(0,0,0,0.5);
}
.adcreative-container .content table tbody tr td:last-child {
  border-right: 1px solid rgba(0,0,0,0.5);
}
.adcreative-container .content table tbody tr:nth-child(even) {
  background: rgba(0,0,0,0.1);
}

.adcreative-container .content ul {
  padding-inline-start: 14px;
}
.adcreative-container .content ul li {
  list-style: disc;
}
.adcreative-container .content ol {
  padding-inline-start: 14px;
}
.adcreative-container .content ol li {
  list-style: decimal;
}
.adcreative-container .content hr {
  border: 0;
  border-top: 1px solid #dfdfdf;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.adcreative-container .footer {
  position: absolute;
  top: 222px;
  left: 0px;
  width: 292px;
  max-width: 292px;
  min-width: 292px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  font-size: 12px;
  line-height: 16px;
  color: white;
  padding: 3px 0 0 0;
  background-color: red;
}
.adcreative-container .field-row.field-style-lcell {
  display: table-row;
}
.adcreative-container .field-row.field-style-lcell .field-label {
  display: table-cell;
  text-align: left;
  padding-right: 4px;
  white-space: nowrap;
}
.adcreative-container .field-row.field-style-lcell .field-value {
  display: table-cell;
  padding-bottom: 2px;
}
.adcreative-container .field-row.field-style-rcell {
  display: table-row;
}
.adcreative-container .field-row.field-style-rcell .field-label {
  display: table-cell;
  text-align: right;
  padding-right: 4px;
  white-space: nowrap;
}
.adcreative-container .field-row.field-style-rcell .field-value {
  display: table-cell;
  padding-bottom: 4px;
}
.adcreative-container .field-row.field-style-inline {
  display: block;
  padding-bottom: 2px;
}
.adcreative-container .field-row.field-style-inline .field-label {
  display: inline;
  padding-right: 4px;
}
.adcreative-container .field-row.field-style-inline .field-value {
  display: inline;
  padding-bottom: 2px;
}
.adcreative-container .field-row.field-style-inline .field-value p:first-of-type {
  display: inline;
}
.adcreative-container .field-row.field-style-newline {
  display: block;
}
.adcreative-container .field-row.field-style-newline .field-label {
  display: block;
}
.adcreative-container .field-row.field-style-newline .field-value {
  display: block;
  padding-bottom: 2px;
}
.adcreative-container .field-row.field-style-label-bold .field-label {
  font-weight: 700;
}
.adcreative-container .field-row.field-style-value-bold .field-value {
  font-weight: 700;
}
.adcreative-container .field-row.field-style-label-hidden .field-label {
  display: none;
}
.adcreative-container .field-row.field-style-label-hidden .field-value {
  display: block;
}

.iframe-embed {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
}
.iframe-embed-wrapper {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    width: 90%;
}
.iframe-embed-responsive-16by9 {
    padding-bottom: 56.25%;
}

.make-me-sticky {
  position: -webkit-sticky;
	position: sticky;
	top: 74px;   
}
.make-me-sticky2 {
  position: -webkit-sticky;
	position: sticky;
	top: 340px;   
  padding: 0 15px;
}

.secondary-photo {
  width: 300px;
  height: 400px;
}

/* Misc */

.drop-item a {
  text-decoration: none;
}

.drop-item a:hover {
  text-decoration: none;
}

.dropdown-menu.show {
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75) !important;
}

/*a {*/
/*  color: white;*/
/*  text-decoration: none;*/
/*  border-bottom: 2px solid transparent;*/
/*}*/

/*a:hover {*/
/*  color: white;*/
/*  text-decoration: none;*/
/*  border-bottom: 2px solid var(--link-highlight-color);;*/
/*}*/

.popEstimations td {
  padding: 4px;
}

.popEstimations td.label {
  color: var(--form-control-label-color)
}

.popEstimations td.value {
  color: var(--form-control-label-emphasized-color)
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25em;
  font-size: var(--form-control-font-size);
  min-height: 2.5em;
  white-space: nowrap;
  color: var(--button-text-color);
  background-color: var(--button-background-color);
  border-color: var(--button-border-color);
}

.btn:focus:not(:disabled) {
  border-color:  var(--button-active-border-color) !important;
}

.btn:hover:not(:disabled) {
  color: var(--button-active-text-color) !important;
  background-color: var(--button-active-background-color) !important;
  border-color: var(--button-border-color) !important;
}

.btn.disabled {
  color: var(--button-inactive-text-color) !important;
  background-color: var(--button-inactive-background-color) !important;
  border-color: var(--button-inactive-border-color) !important;
}

.btn:disabled {
  color: var(--button-inactive-text-color) !important;
  background-color: var(--button-inactive-background-color) !important;
  border-color: var(--button-inactive-border-color) !important;
}

.btn:focus-visible {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn:focus {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-primary {
  color: var(--primary-button-text-color) !important;
  background-color: var(--primary-button-background-color) !important;
  border-color:  var(--primary-button-border-color) !important;
}

.btn-primary:hover:not(:disabled) {
  color: var(--primary-button-active-text-color) !important;
  background-color: var(--primary-button-active-background-color) !important;
  border-color:  var(--primary-button-active-border-color) !important;
}

.btn-primary:focus:not(:disabled) {
  border-color:  var(--primary-button-active-border-color) !important;
}

/*.btn-secondary {*/
/*  color: var(--button-text-color) !important;*/
/*  background-color: var(--button-background-color) !important;*/
/*  border-color: var(--button-border-color) !important;*/
/*}*/

/*.btn-secondary:focus:not(:disabled) {*/
/*  border-color:  var(--button-active-border-color) !important;*/
/*}*/

/*.btn-secondary:hover:not(:disabled) {*/
/*  color: var(--button-active-text-color) !important;*/
/*  background-color: var(--button-active-background-color) !important;*/
/*  border-color: var(--button-border-color) !important;*/
/*}*/

.btn-tertiary {
  color: var(--tertiary-button-text-color) !important;
  background-color: var(--tertiary-button-background-color) !important;
  border-color: var(--tertiary-button-background-color) !important;
}

.btn-tertiary:disabled {
  background-color: transparent !important;
  border: transparent !important;
}

.btn-tertiary:hover:not(:disabled) {
  color: var(--tertiary-button-active-text-color) !important;
  background-color: var(--tertiary-button-active-background-color) !important;
  border-color: var(--tertiary-button-active-background-color) !important;
}

.btn-primary-violet {
  color: var(--primary-violet-button-text-color) !important;
  background-color: var(--primary-violet-button-background-color) !important;
  border-color: var(--primary-violet-button-border-color) !important;
}

.btn-primary-violet:focus:not(:disabled) {
  border-color:  var(--primary-violet-button-border-color) !important;
}

.btn-primary-violet:hover:not(:disabled) {
  color: var(--primary-violet-button-active-text-color) !important;
  background-color: var(--primary-violet-button-active-background-color) !important;
  border-color: var(--primary-violet-button-border-color) !important;
}

.btn-primary-blue {
  color: var(--primary-blue-button-text-color) !important;
  background-color: var(--primary-blue-button-background-color) !important;
  border-color: var(--primary-blue-button-border-color) !important;
}

.btn-primary-blue:focus:not(:disabled) {
  border-color:  var(--primary-blue-button-border-color) !important;
}

.btn-primary-blue:hover:not(:disabled) {
  color: var(--primary-blue-button-active-text-color) !important;
  background-color: var(--primary-blue-button-active-background-color) !important;
  border-color: var(--primary-blue-button-border-color) !important;
}

.btn-tiny {
  padding: 4px 6px;
  margin-right: 2px;
  font-size: 10px !important;
}

.btn:focus {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.btn:hover {
  border-bottom: 0 !important;
  /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 80%); */
} 

/*.login-container {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  height: 100vh;*/
/*  background-color: var(--main-background-color);*/
/*}*/

/*.login-card {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  min-width: 500px;*/
/*  border-radius: 8px;*/
/*  background-color: black;*/
/*  border: 1px solid var(--form-border-color);*/
/*}*/

/*.login-card header {*/
/*  width: 100%;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  color: var(--form-header-text-color);*/
/*  border-bottom: 1px solid var(--form-divider-color);   */
/*}*/

/*.login-card img {*/
/*  width: 100px;*/
/*  height: auto;*/
/*  padding: 20px 0 12px 0;*/
/*}*/

/*.login-card main {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: flex-start;*/
/*  width: 500px;*/
/*  min-height: 294px;*/
/*  padding: 20px;*/
/*  color: var(--form-text-color);*/
/*  background-color: rgb(var(--form-background-color-rgb), var(--form-header-background-color-alpha)) !important; !*var(--form-background-color);*!*/
/*}*/

/*.login-card main section {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  margin-top: 18px;*/
/*  text-align: center;*/
/*} */

/*.login-card main h1 {*/
/*  font-size: 20px;*/
/*  margin-bottom: 16px;*/
/*  color: var(--form-header-text-color);*/
/*}*/

/*.logn-card input[type=text].form-control {*/
/*  font-size: var(--form-control-font-size);*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-background-color); */
/*  border: 1px solid var(--form-control-border-color);*/
/*}*/

/*.logn-card input[type="text"]:focus.form-control  {*/
/*  font-size: var(--form-control-font-size);*/
/*  color: var(--form-control-text-color);*/
/*  background-color: var(--form-control-background-color); */
/*}*/

/*.login-card a {*/
/*  color: var(--form-link-text-color);*/
/*  text-decoration: none;*/
/*  border-bottom: 1px solid var(--form-link-border-color);*/
/*  cursor: pointer;*/
/*}*/

/*.login-card a:hover {*/
/*  color: var(--form-link-hilite-text-color);*/
/*  text-decoration: none;*/
/*  border-bottom: 2px solid var(--form-link-hilite-border-color); !*var(--link-highlight-color);;*!*/
/*}*/


/*.login-card p {*/
/*  font-size: 14px;*/
/*  color: var(--form-text-color);*/
/*  line-height: 24px;*/
/*  padding: 0px;*/
/*  margin: 0px;*/
/*  text-align: center;*/
/*}*/

/*.login-card .btn-login {*/
/*  padding: 0;*/
/*  !* margin: 20px; *!*/
/*  border: 0;*/
/*  outline: 0;*/
/*  background-color: #333;*/
/*}*/

/*.login-card .btn-login:focus {*/
/*  border: 0;*/
/*  outline: 0;  */
/*}*/

/*.login-card footer {*/
/*  font-size: 12px;*/
/*  color: #aaa;*/
/*  padding: 14px;*/
/*}*/

/*.login-card footer a {*/
/*  color: white;*/
/*}*/

/*.login-card footer a:hover {*/
/*  color: white;*/
/*}*/

/* #dlg_publish .modal-body {
  color: white;
}

#dlg_publish .modal-body section.main {
  color: white;
  padding: 0px 20px 20px 10px;
}


#dlg_publish .modal-body section.main label {
  color: #ccc;
}

*/

#dlg_publish .modal-body section.main .loc {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px;
}

#dlg_publish .modal-body section.main .loc::before {
  content: '\022C5';
  padding-right: 4px;
}

#dlg_publish .modal-body section.main li {
  list-style: disc;
}

#dlg_publish .modal-body section.paypal {
  padding: 0 20px 0 10px;
}

.over-budget {
  color: red !important;
}

.dropdown-menu {
  color: var(--menu-text-color) !important;
  background-color: var(--menu-background-color) !important;
  border: 1px solid var(--menu-border-color) !important;
}

.dropdown-item {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 0.25em;
  color: var(--menu-link-text-color) !important;
  background-color: var(--menu-link-background-color) !important;
  border: 1px solid var(--menu-link-border-color) !important;
  font-size: var(--menu-font-size) !important;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: var(--menu-link-underline-color) !important;
  text-underline-offset: var(--menu-underscore-offset) !important;
  text-decoration-thickness: var(--menu-underscore-thickness) !important;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--menu-link-active-text-color) !important;
  text-decoration: underline;
  text-decoration-color: var(--menu-link-active-underline-color) !important;
  background-color: var(--menu-link-active-background-color) !important;
  border:1px solid var(--menu-link-active-border-color) !important;
}

.dropdown-item .muted {
  color: var(--dropdown-item-muted-text-color);
}

.dropdown-divider {
  border-top: 1px solid var(--menu-border-color) !important;
}

.user a:hover {
  border-bottom: 0 !important;
}

label.sortable-column {
  cursor: pointer;
  margin-bottom: 0;
}

.fa-ul {
  margin-bottom: 0 !important;
}

.tag-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Tahoma";
  font-size: 6pt;
  font-weight: 400;
  line-height: 9px;
  max-height: 20px;
  width: max-content;
  text-transform: uppercase;
  color: var(--tag-label-default-text-color);
  background-color: var(--tag-background-color);
  border: 1px solid var(--tag-border-color);
  padding: 4px;
  margin: 2px;
  cursor: default;
  border-radius: 4px;
  gap: 0.5em;
}

.tag-label b {
  /*font-weight: 400;*/
  color: var(--tag-label-hilite-text-color);
}

.tag-label.tag-label-sm {
  font-size: 6px;
  line-height: 7px;
  max-height: 17px;
}

.tag-label.tag-label-lg {
  font-size: 11px;
  line-height: 12px;
  max-height: 22px;
}

.tag-box {
  display: flex;
  align-items: center;
}

.tag-label-default {
  color: var(--tag-label-default-text-color);
}

.tag-label-inactive {
  color: var(--tag-label-inactive-text-color);
}

.tag-label-todo {
  color: var(--tag-label-todo-text-color);
}

.tag-label-doing {
  color: var(--tag-label-doing-text-color);
}

.tag-label-done {
  color: var(--tag-label-done-text-color);
}

.tag-label-error {
  color: var(--tag-label-error-text-color);
}

.tag-label-warning {
  color: var(--tag-label-warning-text-color);
}

.tag-label-hilite {
  color: var(--tag-label-hilite-text-color);
}

.broadcast-status-color-stopped {
  color: var(--tag-label-error-text-color) !important;
}
.broadcast-status-color-refunded {
  color: var(--tag-label-error-text-color) !important;
}
.broadcast-status-color-delivering {
  color: var(--tag-label-doing-text-color) !important;
}
.broadcast-status-color-draft {
  color: var(--tag-label-default-text-color) !important;
}
.broadcast-status-color-ended {
  color: var(--tag-label-done-text-color) !important;
}
.broadcast-status-color-expired {
  color: var(--tag-label-done-text-color) !important;
}
.broadcast-status-color-generated {
  color: var(--tag-label-todo-text-color) !important;
}
.broadcast-status-color-imported {
  color: var(--tag-label-todo-text-color) !important;
}
.broadcast-status-color-published {
  color: var(--tag-label-todo-text-color) !important;
}
.broadcast-status-color-awaiting_stats {
  color: var(--tag-label-todo-text-color) !important;
}
.broadcast-status-color-started {
  color: var(--tag-label-doing-text-color) !important;
}
.broadcast-status-color-running {
  color: var(--tag-label-doing-text-color) !important;
}
.broadcast-status-color-paused {
  color: var(--tag-label-inactive-text-color) !important;
}

.broadcast-status-color-failed_import {
  color: var(--tag-label-error-text-color) !important;
}

.broadcast-status-color-payment_pending {
  color: var(--tag-label-warning-text-color) !important;
}

.user-status-color-inviting {
  color: var(--tag-label-todo-text-color) !important;
}
.user-status-color-inactive {
  color: var(--tag-label-inactive-text-color) !important;
}
.user-status-color-delivered {
  color: var(--tag-label-inactive-text-color) !important;
}
.user-status-color-active {
  color: var(--tag-label-doing-text-color) !important;
}
.user-status-color-expired {
  color: var(--tag-label-error-text-color) !important;
}
.user-status-color-banned {
  color: var(--tag-label-error-text-color) !important;
}
.user-status-color-blocked {
  color: var(--tag-label-error-text-color) !important;
}

.credit-transaction-status-color-active {
  color: var(--tag-label-done-text-color) !important;
}
.credit-transaction-status-color-inactive {
  color: var(--tag-label-inactive-text-color) !important;
}
.credit-transaction-status-color-expired {
  color: var(--tag-label-error-text-color) !important;
}
.credit-transaction-status-color-reversed {
  color: var(--tag-label-warning-text-color) !important;
}
.credit-transaction-status-color-pending {
  color: var(--tag-label-warning-text-color) !important;
}

.inactive-Link {
  pointer-events: none;
  cursor: default;
}

.table {
  color: var(--text-color) !important;
  background-color: var(--table-background-color) !important;
  margin-bottom: 0 !important;
}

.table thead {
  color:var(--text-color) !important;
}

.table thead th {
  font-weight: var(--table-header-text-weight);
  color: var(--table-header-text-color) !important; /* #fff; */
  background-color: var(--table-header-background-color) !important; /*#303030;*/
}

.table tfoot {
  color:var(--text-color) !important;
}

.table tfoot th {
  font-weight: var(--table-header-text-weight);
  color: var(--table-header-text-color) !important; /* #fff; */
  background-color: var(--table-header-background-color) !important; /*#303030;*/
}

.table th, .table td {
  border-top: 1px solid var(--table-border-color) !important;
}

.table th.col-clickable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.table th.col-clickable:hover {
  color: var(--table-header-hilite-text-color) !important;
  background-color: var(--table-header-hilite-background-color) !important;
  cursor: pointer;
}

.table tbody tr {
  border-top: 0 solid transparent !important;
}

.table tbody tr:hover td {
  color: var(--table-hilite-text-color) !important;
  background-color: var(--table-hilite-background-color) !important;
  cursor: pointer;
}

.table a {
  /*color: var(--form-link-text-color);*/
  /*text-decoration: none;*/
  /*border-bottom: 1px solid var(--form-link-border-color);*/
}

.table a:hover {
  /*color: var(--form-link-hilite-text-color);*/
  /*text-decoration: none;*/
  /*border-bottom: 1px solid var(--form-link-hilite-border-color);*/
}

.table-bordered {
  border: 1px solid var(--table-border-color) !important;
}

.table-bordered th, .table-bordered td {
  border: 1px solid var(--table-border-color) !important;
}

.table-striped tbody tr:nth-of-type(odd){
  background-color: var(--table-row-odd-background-color) !important; /*#303030*/
}

.table-hover th.col-clickable:hover {
  background-color: var(--table-header-hilite-background-color) !important;
  cursor: pointer;
}

.input-group-prepend .btn, .input-group-append .btn {
  z-index: initial !important;
}

/* .form-group.search .input-group-prepend {
  background-color: var(--form-control-background-color);
  border-left: 1px solid var(--form-control-border-color); 
  border-top: 1px solid var(--form-control-border-color); 
  border-bottom: 1px solid var(--form-control-border-color); 
}

.form-group.search .input-group-prepend .input-group-text {
  background-color: var(--form-control-background-color);
}

.form-group.search input {
  border-left: 0 !important;
} */

.actions button {
  /* margin: 20px; */
}

.actions input {
  margin-top: 17px;
}

.actions .form-group {
  max-height: 20px;
}

.search input {
  min-width: 300px;
}

.search .form-control {
  padding: 16px;
  padding-left: 32px;
  margin: -1px;
}

.search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 24px;
  height: 38px;
  text-align: right;
  pointer-events: none;
  color: #aaa;
  padding-left: 10px;
}

/*button.btn svg {*/
/*  margin-top: 2px;*/
/*  margin-right: 4px;*/
/*}*/

code {
  color: var(--form-helptext-color);
  word-wrap: break-word;
  font-weight: 700;
  font-family: monospace;
}

kbd {
  display: inline-block;
  font-family: monospace;
  font-size: 87.5%;
  font-weight: 700;
  color: var(--kbd-text-color) !important;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid var(--kbd-border-color);
  padding: 0.01rem 0.2rem;
  margin: 0 0.2rem;
}

.but {
  display: inline-block;
  font-family: monospace;
  font-size: 87.5%;
  font-weight: 500;
  color: var(--kbd-text-color) !important;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid var(--kbd-border-color);
  padding: 0.01rem 0.2rem;
  margin: 0 0.2rem;
}

.editor-wrapper {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-editor {
  color: var(--form-control-text-color) !important; 
  background-color: var(--form-control-background-color) !important;
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-toolbar {
  /* border: 1px solid #ccc; */
}

a.display-only {
  color: var(--text-color);
  text-decoration: none !important;
  border-bottom: 0 !important;
}

#sticky-sidebar {
  position: fixed;
  top: 45px;
  max-width: 20%;
}

.settings-sidebar-container {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
  font-size: 16px;
  color: var(--nav-text-color);
}

.settings-sidebar-header {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #999;
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid #333 !important;
}

.settings-sidebar-container ul {
  padding: 0;
}

.settings-sidebar-container ul li {
  padding-bottom: 10px;
  white-space: nowrap;
}

.settings-sidebar-container ul li a {
  color: var(--nav-text-color);
}

.settings-sidebar-container ul li a:hover {
  color: var(--nav-focus-color);
}

.settings-sidebar-container ul li a.active {
  color: var(--nav-focus-color);
  border-bottom: 2px solid var(--form-link-active-underline-color);
}

.helptext {
  font-size: var(--form-helptext-font-size);
  font-weight: 400;
  color: var(--form-caption-color);
}

ul.helptext, ol.helptext {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  font-size: var(--form-helptext-font-size);
  font-weight: 400;
  margin: 8px 0 0 2px;
  padding-left: 0;
  text-align: left;
  color: var(--form-caption-color);
}

ul.helptext  li {
  position: relative;
  padding-left: 0;
}

ol.helptext li {
  margin-left: 12px;
}

ul.helptext li::before {
  position: absolute;
  left: 0;
  top: -1px;
  content: '•';
  color: var(--form-title-color);
  font-size: 12px;
  margin-right: 6px;
}

ul.helptext  li span {
  display: block;
  text-indent: 0;
  padding-left: 10px;
}

ul.helptext li.d {
  font-weight: 400;
  list-style: none !important;
}

ul.helptext li.dd {
  list-style: disc;
  margin-left: 15px;
}

ul.helptext li.v {
  list-style: none !important;
  color: inherit;
  font-weight: 400;
}

ul.helptext li .but {
  color: var(--form-caption-color) !important;
  border: 1px solid var(--form-caption-color);
  margin: 0 0.1rem;
}

ol.helptext li .but {
  color: var(--form-caption-color) !important;
  border: 1px solid var(--form-label-color);
  margin: 0 0.1rem;
}

ul.helptext li code {
  font-size: 87.5%;
  font-weight: 400;
  color: inherit;
  background-color: inherit;
  word-wrap: break-word;
}

ul.helptext li mark {
  font-weight: 700;
  color: var(--form-label-color);
  background-color: transparent !important;
}

ul.helptext li em {
  font-style: normal;
  font-weight: 400;
  color: var(--form-label-color);
}

.form-group .v-error {
  font-weight: 700 !important;
  color: var(--form-control-error-color) !important;
}

.form-group .v-warning {
  font-weight: 400 !important;
  color: var(--form-control-warning-color) !important;
}

/*.form-group.readonly ul.helptext li.v {*/
/*  display: none !important;*/
/*}*/

/* .form-group.row label {
  text-align: right;
  white-space: nowrap;
} */

.form-control {
  background-color: var(--form-control-background-color);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.toggle-btn {
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 14px;
  border-radius: 4px;
  color: white;
  padding: 1px;
  margin: 3px 3px 3px 0;
  cursor: pointer;
  background-color: var(--button-background-color); /*var(--button-background-color);*/
  border: 1px solid var(--button-border-color);
}

.toggle-btn .toggle-btn-label {
  color: var(--button-text-color);
  flex: 1 1 auto;
  padding: 6px;
}

.toggle-btn .toggle-btn-indicator {
  flex: 0 0 auto;
  width: 12px;
  height: 90%;
  background-color: var(--button-text-color);
  border-radius: 4px;
}

.toggle-btn:hover {
  background-color: var(--button-active-background-color);
}

.toggle-btn:hover .toggle-btn-label {
  color: var(--button-active-text-color);
}

.toggle-btn:hover .toggle-btn-indicator {
  background-color: var(--button-active-text-color);
}

.toggle-btn.readonly {
  background-color: var(--form-control-inactive-background-color);
  cursor: default;
}

.toggle-btn.readonly:hover  .toggle-btn-label {
  color: var(--button-text-color);
}

.toggle-btn.readonly:hover  .toggle-btn-indicator {
  background-color: var(--button-text-color);
}

/* custom radio button */

.radio-inline {
  display: inline-block;
  position: relative;
  padding: 0 0 0 26px;
  margin: 0 0 2px 0;
  color: var(--form-control-label-color);
  cursor: pointer;
  font-size: var(--form-control-font-size);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-group.row .radio-inline {
  margin: 8px 0 2px 0;
}

.form-group.readonly .radio-inline {
  cursor: default;
}

/* Hide the browser's default radio button */
.radio-inline input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  font-size: var(--form-control-font-size);
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--form-control-background-color); /*var(--button-background-color); */
  border: 1px solid var(--form-control-border-color);
  border-radius: 50%;
}

.radio-inline:hover input ~ .checkmark {
  background-color: var(--button-active-background-color);
}

.radio-inline input:checked ~ .checkmark {
  background-color:  var(--form-control-background-color); /*var(--button-background-color); */
}

.form-group.readonly .radio-inline:hover input:checked ~ .checkmark {
  border: 1px solid var(--form-control-border-color);
}

.form-group.readonly .radio-inline:hover input ~ .checkmark {
  border: 1px solid var(--form-control-border-color);
}

.form-groups {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: left;
  background-color: var(--form-group-background-color);
  /*padding: 18px;*/
  border: 1px solid var(--form-group-border-color);
  border-radius: 5px;
  margin-top: 0;
  margin-bottom: 10px !important;
}

.form-groups .form-groups {
  border: none;
}

.form-groups .form-groups-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  border-bottom: 1px solid var(--form-divider-color);
}

.form-groups .form-groups-header .form-groups-title {
}

.form-groups .form-groups-header .form-groups-title h1 {
  display: block;
  font-size: var(--form-control-font-size);
  font-weight: 400;
  color: var(--form-control-label-color);
  width: 100%;
  margin: 0 !important;
}

.form-groups .form-groups-header .form-groups-title small {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: var(--form-caption-color) !important;
  width: 100%;
}

.form-groups .form-groups-header .form-groups-controls {
  display: flex;
  gap: 6px;
}

.form-groups .form-group {
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  border: none;
}

.form-groups .form-group:first-child {
  /*padding-top: 0 !important;*/
}

.form-groups .form-group:last-child {
  /*padding-bottom: 0 !important;*/
}

.form-groups .form-group:not(:last-child) {
  padding-bottom: 0 !important;
}

.form-groups .form-groups .form-group {
  margin-left: 2rem !important;
}

.form-groups .form-groups h1 {
  border: 0 !important;;
  padding-bottom: 0 !important;
}

.form-groups .form-groups h1::after {
  content: ':';
  color: var(--form-caption-color) !important;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-inline input:checked ~ .checkmark:after {
  display: inline-block;
}

/* Style the indicator (dot/circle) */
.radio-inline .checkmark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--button-text-color);
}

.modal-md {
  max-width: 650px;
}

.modal-content {
  color: var(--form-text-color);
  background-color: var(--form-background-color) !important; /*var(--form-background-color); var(--form-background-color) !important; */
  padding: 0 30px !important;
}

.modal-header {
  display: flex;
  flex-direction: column;
  background-color: var(--form-background-color) !important; /*var(--form-background-color);*/
  border-bottom: 1px solid var(--form-divider-color) !important;
  padding: 20px 0 15px 0 !important;
  margin-bottom: 8px;
}

.modal-header .close {
  color: var(--button-text-color);
  outline: 0 !important;
}

.modal-header .close:focus-visible {
  outline: 0 !important;
}

.modal-title-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.modal-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.modal-title h1 {
  font-size: 15pt;
  font-weight: 500;
  margin: 0;
}

.modal-title h2 {
  font-size: 15pt;
  font-weight: 500;
  margin: 0;
}

.modal-title h3 {
  font-size: 14pt;
  font-weight: 400;
  margin: 0;
}

.modal-title h4 {
  font-size: 14pt;
  font-weight: 400;
  margin: 0;
}

.modal-title small {
  display: block;
  color: var(--form-caption-color);
}

.modal-title p {
  margin: 0;
}

.modal-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modal-footer {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: inherit;
  background-color: rgb(var(--form-background-color-rgb), 1) !important; /*var(--form-background-color);*/
  border-top: 1px solid var(--form-divider-color) !important;
  padding: 15px 0 15px 0 !important;
  margin-top: 8px;
}

.modal-body {
  background-color: rgb(var(--form-background-color-rgb), 1) !important; /*var(--form-background-color);*/
  padding: 10px 2px !important;
}

.modal-body small {
  color: var(--form-caption-color) !important;
}

.modal.responsive {
  padding: 0 !important; /* override inline padding-right added from js */
}
.modal.responsive .modal-dialog  {
  width: 90%;
  max-width: none;
  height: 90%;
  margin: auto;
}
.modal.responsive .modal-content  {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal.responsive .modal-body  {
  overflow-y: auto;
}

#inp_creativeBorderColor {
  background-color: transparent;
  padding: 0;
  width: 150px;
}

.modal-body .form-card {
  border: 0 !important;
  margin: 0 !important;
  padding-bottom: 0 !important;
}

/* Utilities */

.color-subtext {
  color: var(--color-subtext) !important;
}

.text-danger {
  color: var(--color-danger) !important;
}

.text-warning {
  color: var(--color-warning) !important;
}

.text-muted {
  color: var(--color-subtext) !important;
}

.text-outline {
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: 2px red;
}

.text-link {
  color: var(--form-text-color);
  /*color: var(--form-link-text-color);*/
  text-decoration: underline !important;
  text-decoration-color: var(--form-link-underline-color) !important;
  text-underline-offset: 3px;
  font-weight: 400;
  cursor: pointer;
}

.text-link:hover {
  color: var(--form-link-active-text-color);
  text-decoration: underline !important;
  text-decoration-color: var(--form-link-active-underline-color) !important;
  font-weight: 400;
  cursor: pointer;
}

.text-link:focus-visible {
  outline: 0 !important;
}

.def-link {
  color: var(--form-link-text-color);
  text-decoration: underline !important;
  text-decoration-style: dotted !important;
  text-decoration-color: var(--form-link-underline-color) !important;
  text-underline-offset: 3px;
  font-weight: 500;
  cursor: pointer;
}

.def-link:hover {
  color: var(--form-link-active-text-color);
  text-decoration: underline !important;
  text-decoration-style: dotted !important;
  text-decoration-color: var(--form-link-active-underline-color) !important;
  font-weight: 500;
  cursor: pointer;
}


.icon-link {
  color: var(--form-link-icon-color);
  text-decoration: none !important;
  cursor: pointer;
}

.icon-link:hover {
  color: var(--form-link-active-icon-color);
  text-decoration: none;
  cursor: pointer;
}

.help-icon-link {
  color: var(--form-link-icon-color);
  text-decoration: none !important;
  cursor: pointer;
  border: 1px solid #666;
  border-radius: 100%;
}

.help-icon-link:hover {
  color: var(--form-link-active-icon-color);
  text-decoration: none;
  text-decoration-color: var(--form-link-active-underline-color) !important;
  cursor: pointer;
}

.talign-left {
  text-align: left !important;
}

.talign-right {
  text-align: right !important;
}

.talign-center {
  text-align: center !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.dropdown-caret-off::before {
  display: none;
}
.dropdown-caret-off::after {
  display: none;
}

.pricing-plans {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.pricing-plan {
  flex-grow: 1;
  min-width: 200px;
  max-width: calc(50% - 20px);
  text-align: left;
  /*max-width: 400px;*/
  padding: 40px 20px 20px 20px;
  border: 1px solid #555;
  border-radius: 5px;
}

.pricing-plan h1 {
  color: var(--form-text-color);
  font-size: 20pt;
  font-weight: 400 !important;;
}

.pricing-plan h2 {
  color: var(--form-text-color);
  font-size: 16pt;
  font-weight: 500 !important;
}

.pricing-plan h3 {
  color: var(--form-text-color);
  font-size: 14pt;
  font-weight: 700 !important;
}

.pricing-plan-discount {
  position: relative;
  font-size: 10pt;
  font-weight: 500;
  color: var(--form-title-color);
  margin-left: 8px;
}

.pricing-plan-original {
  position: relative;
  font-size: 14pt;
  font-weight: 500;
  color: var(--form-caption-color);
  text-decoration: line-through;
  text-decoration-color: var(--form-title-color);
  margin-left: 8px;
}

.pricing-plan-recommended {
  /*background-color: rgb(125 173 244 / .4) !important; !*rgb(138 43 226 / 0.5) !important;*!*/
}

.pricing-plan-ribbon {
  background-color: #007bff; /*rgb(138 43 226 / 0.7);*/
  font-size: 9pt;
  font-weight: bold;
  padding: 4px 8px 6px 8px;
  border-radius: 0.25em;
  margin-top: -52px;
  margin-bottom: 25px;
  width: fit-content;
}

.pricing-plan-per {
  color: #ccc;
  font-size: 12pt;
  font-weight: 400;
}

a.pricing-plan-per {
  text-decoration: underline;
  text-decoration-color: #888;
  text-decoration-style: dotted;
  text-underline-offset: 4px;
}

@media (max-width: 600px) {
  .pricing-plans {
    flex-direction: column;
  }

  .pricing-plan {
    max-width: none;
    width: 100%;
  }
}

/*a[href^="https://"]:after {*/
  /*font-family: FontAwesome;*/
  /*content: "\f35d";*/
  /*font-weight: normal;*/
  /*display: inline-block;*/
  /*text-decoration: none;*/
  /*font-family: 'FontAwesome';*/
  /*font:normal normal normal 14px/1 FontAwesome;*/
  /*font-weight: normal;*/
  /*content: '\f042';*/
  /*display: inline-block;*/
  /*text-decoration: none;*/
  /*vertical-align: middle;*/
  /*padding-left: 3px;*/
/*}*/

.btn-signin {
  display: inline-flex;
  align-items: center;
  width: 210px;
  height: 44px;
  padding: 0;
  border-radius: 5px;
  /*border: 1px solid #ccc"; */
}

.btn-signin-icon {
  font-size: 18px;
  font-weight: 400;
  padding: 10px 8px 10px 12px;
}

.btn-signin-text {
  font-size: 14px;
  font-weight: 700;
  padding: 8px 8px 8px 6px;
}

.btn-signin-email {
  color: var(--primary-button-text-color);
  background-color: var(--primary-button-background-color);
  border: 1px solid var(--primary-button-background-color);
}

.color-white {
  color: white !important;
}

.color-warning {
  color: var(--form-control-warning-color) !important;
}

.color-error {
  color: white !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.g-recaptcha {
  overflow: hidden;
  width: 298px;
  height: 74px;
}

.greeting {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  color: var(--form-text-color);
  font-size: 15pt;
}

.menu-icon {
  width: 30px !important;
}

.credits-payment-container {
  margin-top: -2px;
  max-width: 400px;
  padding: 0 10px 10px 10px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

}

.credits-payment-container hr {
  padding-inline: 10px;
}

.credits-payment-container button {
  width: 100%;
  height: 35px;
}

.paypal-buttons-container {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 3px;
}

.bank-transfer-container {
  margin-top: -18px;
  max-width: 400px;
  padding: 0 10px 10px 10px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.bank-transfer-container hr {
  padding-inline: 10px;
}

.bank-transfer-container button.btn-bank-transfer-toggle {
  width: 100%;
  height: 35px;
}

.bank-transfer-instructions-container {
  color: var(--form-control-label-color);
}

.bank-transfer-container .bank-transfer-instructions-container {
  display: none;
}

.bank-transfer-container.selected .bank-transfer-instructions-container {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  gap: 0.5em;
}

.bank-transfer-container.selected button.btn-bank-transfer-toggle {
  display: none;
}

.bank-transfer-instructions-container .key-value-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.bank-transfer-instructions-container .key-value-container {
  font-size: var(--form-control-font-size);
  font-weight: 400;
}

.bank-transfer-instructions-container .key-value-container label {
  margin-bottom: 0;
}

.bank-transfer-instructions-container .key-value-container .key {
  font-weight: 400;
  color: #666;
}

.bank-transfer-instructions-container .key-value-container .value {
  font-weight: 600;
  color: #000;
}

.bank-transfer-details-container {
  color: var(--form-control-label-color);
  padding: 20px;
  border: 1px solid var(--form-divider-color);
}

.bank-transfer-details-container h1 {
  font-size: 12pt;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--form-divider-color);
}

.bank-transfer-details-markdown {
  color: var(--form-control-label-color);
}

.bank-transfer-details-markdown table tr td {
  padding: 2px;
}

.bank-transfer-details-markdown table tr td:first-child {
  white-space: nowrap;
  vertical-align: top;
}

.credits-invoice-wrapper {
  display: none;
}

/*
<div className="d-flex flex-row justify-content-between gap-1" style={{backgroundColor: "#fff"}}>
            <div className="d-flex flex-fill m-1" style={{backgroundColor:"var(--form-background-color)"}}>&nbsp;</div>
            <div className="d-flex flex-fill m-1"  style={{backgroundColor:"var(--form-group-background-color)"}}>&nbsp;</div>
            <div className="d-flex flex-fill m-1"  style={{backgroundColor:"var(--form-control-active-background-color)"}}>&nbsp;</div>
            <div className="d-flex flex-fill m-1"  style={{backgroundColor:"var(--menu-background-color)"}}>&nbsp;</div>
 */
.theme-preview {
  display: flex;
  justify-content: space-between;
  gap: 0.5px;
  background-color: #fff;
}

.theme-preview > div {
  flex: 1;
  height: 10px;
}

.theme-preview[data-theme="pl"] > div:nth-child(1) {
  background-color: #521d52;
}

.theme-preview[data-theme="bluescloudsmoothie"] > div:nth-child(1) {
  background-color: #351f5c;
}

.theme-preview[data-theme="violetnoir"] > div:nth-child(1) {
  background-color: #933f95;
}

.theme-preview[data-theme="violetnoir"] > div:nth-child(1) {
  background-color: #000;
}

.theme-preview[data-theme="violetnoir"] > div:nth-child(1) {
  background-color: #521d52;
}

.theme-preview[data-theme="violetnoir"] > div:nth-child(1) {
  background-color: #351f5c;
}

.theme-preview[data-theme="violetnoir"] > div:nth-child(1) {
  background-color: #933f95;
}

.theme-preview[data-theme="violetnoir"] > div:nth-child(1) {
  background-color: #000;
}

@media print {
  .credits-invoice-wrapper {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .col-form-label {
    text-align: left !important;;
  }

  .form-group.row .col-form-label {
    text-align: left !important;;
  }
}



