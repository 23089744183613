/* Variables */
:root {
    --ext-page-preview-width: 1440px;
    --ext-page-preview-height: 1400px;
    --ext-page-preview-zoom: 0.5;    
}

.ext-page-preview {
}

.ext-page-preview.ext-page-loading {
  background: var(--form-control-background-color) url('/images/spinner-white.svg') center center no-repeat;
}

.ext-page-preview img {
  width: 100%;
}

.ext-page-preview.ext-page-loading img {
  display: none;
}

/* Ad page */

.adpage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  /*color: black;*/
}
.adpage-container .adpage-page-contact-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin: 20px 0 20px 0;
}
.adpage-container .adpage-page-contact-container .adpage-logo-container {
  display: block;
  object-fit: contain;
}
.adpage-container .adpage-page-contact-container .adpage-logo-container img {
  max-width: 100px;
  max-height: 100px;
}
.adpage-container .adpage-page-contact-container .adpage-contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  overflow: hidden;
  white-space: pre-wrap;
}
.adpage-container .adpage-page-contact-container .adpage-contact-container h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0;
}
.adpage-container .adpage-page-contact-container .adpage-contact-container a {
  color: inherit;
  text-decoration: underline;
  border-bottom: 0;
}
.adpage-container .adpage-page-contact-container .adpage-contact-container a:hover {
  text-decoration: underline;
  border-bottom: 0;
}
.adpage-container .adpage-page-contact-container .adpage-contact-container p {
  font-size: 12px;
  font-weight: 400;
  margin-top: 0.2rem;
  margin-bottom: 0;
}
.adpage-container .adpage-page-header {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  background-color: red;
  line-height: 36px;
  width: 90%;
  min-height: 36px;
  text-align: --webkit-center;
  text-align: center;
}
.adpage-container .adpage-page-content-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  padding: 6px;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container {
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
  width: 100%;
  max-width: 600px;
}
.adpage-container .adpage-page-content-container .adpage-page-content-img-container {
  min-width: 250px;
}
.adpage-container .adpage-page-content-container .adpage-page-content-img-container img {
  max-width: 460px;
  object-fit: contain;
  width: 100% !important;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container h1 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 18px;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields {
  overflow: hidden;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields h1, h2, h3, h4, h5, h6 {
  margin: 0 0 18px 0;
  font-weight: 700;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields h1 {
  font-size: 30px;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields h2 {
  font-size: 28px;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields h3 {
  font-size: 24px;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields h4 {
  font-size: 20px;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields h5 {
  font-size: 16px;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields h6 {
  font-size: 14px;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields p {
  margin-bottom: 16px;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields a {
  color: inherit;
  text-decoration: underline;
  border-bottom: 0;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields a:hover {
  text-decoration: underline;
  border-bottom: 0;
}

.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields table {
  width: 99%;
  margin: 0 2px 16px 2px;
  border: 0;
  border-top: 1px solid rgba(0,0,0,0.5);
  border-bottom: 1px solid rgba(0,0,0,0.5);
  border-spacing: 0;
  text-align: left;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields table th {
  font-weight: 700;
  padding: 10px;
  border-left: 1px solid rgba(0,0,0,0.5);
  background: rgba(0,0,0,0.1);
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields table td {
  border-left: 1px solid rgba(0,0,0,0.5);
  border-top: 1px solid rgba(0,0,0,0.5);
  padding: 10px;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields table tbody tr:first-child td {
  border-left: 1px solid rgba(0,0,0,0.5);
  border-top: 1px solid rgba(0,0,0,0.5);
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields table tbody tr td:last-child {
  border-right: 1px solid rgba(0,0,0,0.5);
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields table tbody tr:nth-child(even) {
  background: rgba(0,0,0,0.1);
}

.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields ul li {
  list-style: disc;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields ol li {
  list-style: decimal;
}
.adpage-container .adpage-page-content-container .adpage-page-content-content-container .fields hr {
  border: 0;
  border-top: 1px solid #dfdfdf;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.adpage-container .field-row.field-style-lcell {
  display: table-row;
}
.adpage-container .field-row.field-style-lcell .field-label {
  display: table-cell;
  text-align: left;
  padding-right: 4px;
  white-space: nowrap;
}
.adpage-container .field-row.field-style-lcell .field-value {
  display: table-cell;
  padding-bottom: 10px;
}
.adpage-container .field-row.field-style-rcell {
  display: table-row;
}
.adpage-container .field-row.field-style-rcell .field-label {
  display: table-cell;
  text-align: right;
  padding-right: 4px;
  white-space: nowrap;
}
.adpage-container .field-row.field-style-rcell .field-value {
  display: table-cell;
  padding-bottom: 10px;
}
.adpage-container .field-row.field-style-inline {
  display: block;
  padding-bottom: 10px;
}
.adpage-container .field-row.field-style-inline .field-label {
  display: inline;
  padding-right: 4px;
}
.adpage-container .field-row.field-style-inline .field-value {
  display: inline;
  padding-bottom: 10px;
}
.adpage-container .field-row.field-style-inline .field-value p:first-of-type {
  display: inline;
}
.adpage-container .field-row.field-style-newline {
  display: block;
}
.adpage-container .field-row.field-style-newline .field-label {
  display: block;
}
.adpage-container .field-row.field-style-newline .field-value {
  display: block;
  padding-bottom: 10px;
}
.adpage-container .field-row.field-style-label-bold .field-label {
  font-weight: 700;
}
.adpage-container .field-row.field-style-value-bold .field-value {
  font-weight: 700;
}
.adpage-container .field-row.field-style-label-hidden .field-label {
  display: none;
}
.adpage-container .field-row.field-style-label-hidden .field-value {
  display: block;
}

.adpage-container .alert {
  font-size: 30px;
  font-weight: 700;
  color: white;
  background-color: black;
  padding: 10px;
  margin: 10px;
  width: 100%;
  text-align: center;
}
.adpage-social-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin: 8px;
}
.adpage-footer {
  font-size: 10px;
  color: #555;
  border-top: 1px solid #ccc;
  padding: 20px 0 20px 0;
  width: 90%;
}
.adpage-footer h1 {
  font-size: 10px;
  font-weight: 700;
}

.feedback-table tr.unread td {
  font-weight: 700;
  color: var(--table-unread-text-color);
}

.feedback-table tr.unread td a {
  font-weight: 700;
  color: var(--table-unread-text-color);
}


.feedback-container {
  width: 90%;
  margin: 20px 0 20px 0;
}
.feedback-container h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin: 0 0 10px 0;
}
.feedback-container h1 {
  font-size: 30px;
}
.feedback-container h2 {
  font-size: 28px;
}
.feedback-container h3 {
  font-size: 24px;
}
.feedback-container h4 {
  font-size: 20px;
}
.feedback-container h5 {
  font-size: 16px;
}
.feedback-container h5 {
  font-size: 14px;
}
.feedback-container p {
  margin-bottom: 8px;
}
.feedback-container .form-group {
  margin: 0 0 10px 0;
}
.feedback-container form input {
  font-size: 14px;
  width: 100%;
  padding: 10px;
}
.feedback-container form textarea {
  font-size: 14px;
  width: 100%;
  padding: 10px;
}
.feedback-container form textarea .error {
  border:1px solid red;
}
.feedback-container form label {
  display: block;
  font-weight: 700;
  font-size: 14px;
  margin: 20px 0 8px 0;
}
.feedback-container form button[type="submit"] {
  color: white;
  background-color: red;
  font-weight: 700;
  font-size: 14px;
  padding: 10px;
  margin: 10px 0 0 0;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}
.feedback-container .form-group small {
  display: none;
}
.feedback-container .form-group.invalid input {
  border: 1px solid red;
}
.feedback-container .form-group.invalid input:focus {
  outline: none !important;
  border: 1px solid red;
}
.feedback-container .form-group.invalid textarea {
  border: 1px solid red;
}
.feedback-container .form-group.invalid textarea:focus {
  outline: none !important;
  border: 1px solid red;
}
.feedback-container .form-group.invalid small {
  display: inline-block;
  color: red;
  font-weight: 700 !important;
  font-style: italic !important;
}
.feedback-container .field-row.field-style-lcell {
  display: table-row;
}
.feedback-container .field-row.field-style-lcell .field-label {
  display: table-cell;
  text-align: left;
  padding-right: 4px;
  white-space: nowrap;
  vertical-align: top;
}
.feedback-container .field-row.field-style-lcell .field-value {
  display: table-cell;
  padding-bottom: 10px;
}
.feedback-container .field-row.field-style-rcell {
  display: table-row;
}
.feedback-container .field-row.field-style-rcell .field-label {
  display: table-cell;
  text-align: right;
  padding-right: 4px;
  white-space: nowrap;
}
.feedback-container .field-row.field-style-rcell .field-value {
  display: table-cell;
  padding-bottom: 10px;
}
.feedback-container .field-row.field-style-inline {
  display: block;
  padding-bottom: 10px;
}
.feedback-container .field-row.field-style-inline .field-label {
  display: inline;
  padding-right: 4px;
}
.feedback-container .field-row.field-style-inline .field-value {
  display: inline;
  padding-bottom: 10px;
}
.feedback-container .field-row.field-style-inline .field-value p:first-of-type {
  display: inline;
}
.feedback-container .field-row.field-style-newline {
  display: block;
}
.feedback-container .field-row.field-style-newline .field-label {
  display: block;
}
.feedback-container .field-row.field-style-newline .field-value {
  display: block;
  padding-bottom: 10px;
}
.feedback-container .field-row.field-style-label-bold .field-label {
  font-weight: 700;
}
.feedback-container .field-row.field-style-value-bold .field-value {
  font-weight: 700;
}
.feedback-container .field-row.field-style-label-hidden .field-label {
  display: none;
}
.feedback-container .field-row.field-style-label-hidden .field-value {
  display: block;
}
.thank-you {
  color: black;
  background-color: white;
  padding: 20px;
  text-align: center;
  width: 90%;
  border-radius: 5px;
}
.thank-you a {
  color: #58a6ff;  
  text-decoration: none;
  border-bottom: 0;
}
.thank-you a:hover {
  color: #58a6ff;  
  text-decoration: underline;
  border-bottom: 0;
}
.thank-you h1, h2, h3, h4, h5, h6 {
  margin: 0 0 10px 0;
  font-weight: 700;
}
.thank-you h1 {
  font-size: 30px;
}
.thank-you h2 {
  font-size: 28px;
}
.thank-you h3 {
  font-size: 24px;
}
.thank-you h4 {
  font-size: 20px;
}
.thank-you h5 {
  font-size: 16px;
}
.thank-you h6 {
  font-size: 14px;
}

.email-share-btn {
   color: white;
   background-color: #555;
   padding: 4px;
   width: 80px;
   height: 28px;
   border-radius: 4px;
   margin-left: 4px;
   cursor: pointer;
}
.email-share-btn a {
  color: white;
  font-weight: 700;
  text-decoration: none;
}
.email-share-btn a:hover {
  color: white;
  font-weight: 700;
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .adpage-container .adpage-page-content-container .adpage-page-content-img-container {
    min-width: 250px;
  }
  .adpage-container .adpage-page-content-container .adpage-page-content-content-container {
    max-width: 460px;
  }
}
@media screen and (max-width: 600px) {
  .col-form-label {
    text-align: left;
  }
  .adpage-container .adpage-page-contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .adpage-container .adpage-page-contact-container .adpage-logo-container img {
    max-width: 50px;
    max-height: 50px;
  }
  .adpage-container .adpage-page-contact-container .adpage-contact-container {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-bottom: 10px;
  }
  .adpage-container .adpage-page-contact-container .adpage-contact-container h1 {
    font-size: 14px;
    font-weight: 700;
  }
  .adpage-container .adpage-page-contact-container .adpage-contact-container p {
    font-size: 12px;
    font-weight: 400;
  }
  .adpage-container .adpage-page-header {
    font-size: 16px;
  }
  .adpage-container .adpage-page-content-container {
    flex-direction: column;
    align-items: center;
  }
  .adpage-container .adpage-page-content-container .adpage-page-content-img-container {
    /* width: 100%; */
    max-width: 100%;
  }
  .adpage-container .adpage-page-content-container .adpage-page-content-content-container {
    padding-left: 0;
  }
  .adpage-container .adpage-page-content-container .adpage-page-content-content-container h1 {
    padding-top: 10px;
  }
  .adpage-social-buttons {
    justify-content: center;
  }
}
