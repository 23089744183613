.elct-skin {
  --logo-uri: "https://sassets.elct.io/brand/elct-logo-{color}-{mode}.svg";
  --logo-size: "60x10";

  --accent-color: #895af0;

  /*--main-background: linear-gradient(to bottom right, rgba(28 4 6 / 25%) 20%, rgb(0 0 0 / 80%)), url(https://sassets.elct.io/brand/elct-hero-19.png) center center / cover no-repeat;*/
  --main-background: url(https://sassets.elct.io/brand/elct-hero-31.png) center center / cover no-repeat;

  --menu-link-active-underline-color: var(--accent-color);

  --form-link-active-underline-color: var(--accent-color);

  --pagedialog-header-background-color: #1f026296;
  --pagedialog-sider-background-color: #1f026296;
  --pagedialog-background-color: #222222c4;

  --pagedialog-sider-flex-grow: 0;
  --pagedialog-sider-flex-shrink: 0;
  --pagedialog-sider-flex-basis: 30%;

  --primary-button-background-color: var(--accent-color);
  --primary-button-border-color: #5424bf;
  --primary-button-active-background-color: #763fee;
  --primary-button-active-border-color: #5424bf;
}

@media (prefers-color-scheme: dark) {
  .elct-skin {
  }
}
