:root {
  --pagedialog-sider-flex-grow: 0;
  --pagedialog-sider-flex-shrink: 0;
  --pagedialog-sider-flex-basis: 26%;

  --pagedialog-text-color: #000;
  --pagedialog-text-muted-color: #ccc;
  --pagedialog-text-bright-color: #000;
  --pagedialog-background-color: #ffffffcc;
  --pagedialog-link-text-color: #000;
  --pagedialog-link-border-color: #999;
  --pagedialog-link-active-text-color: #ffffffcc;
  --pagedialog-link-active-border-color: #ffffffcc;
  --pagedialog-header-text-color: #111;
  --pagedialog-header-background-color: #93070355; /*#ce090355;*/ /*#e9e9e9a6;*/
  --pagedialog-footer-text-color: #888;
  --pagedialog-footer-background-color: #161616cc;
  --pagedialog-footer-link-text-color: #ccc;
  --pagedialog-footer-link-border-color: #555;
  --pagedialog-sider-text-color: #ccc;
  --pagedialog-sider-text-bright-color: #fff;
  --pagedialog-sider-text-muted-color: #888;
  --pagedialog-sider-background-color: #93070355; /*#ce090355;*/ /*#161616cc; */ /*#99000155;*/ /*#ce090355; */ /*#e9e9e9a6;*/
  --pagedialog-sider-link-text-color: #eee;
  --pagedialog-sider-link-border-color: #555;
  --pagedialog-border-color: #555;
  --pagedialog-box-shadow: 4px 6px 10px #111;
}

@media (prefers-color-scheme: dark) {
  :root {
    --pagedialog-text-color: #ccc;
    --pagedialog-text-muted-color: #ccc;
    --pagedialog-text-bright-color: #fff;
    --pagedialog-background-color: #222222dd;
    --pagedialog-link-text-color: #fff;
    --pagedialog-link-border-color: #555;
    --pagedialog-link-active-text-color: #ffffffcc;
    --pagedialog-link-active-border-color: #ffffffcc;
    --pagedialog-header-text-color: #111;
    --pagedialog-header-background-color: #93070355; /*#ce090355;*/
    --pagedialog-footer-text-color: #888;
    --pagedialog-footer-background-color: #161616cc;
    --pagedialog-footer-link-text-color: #ccc;
    --pagedialog-footer-link-border-color: #555;
    --pagedialog-sider-text-color: #ccc;
    --pagedialog-sider-text-bright-color: #fff;
    --pagedialog-sider-text-muted-color: #888;
    --pagedialog-sider-background-color: #93070355; /*#ce090355;*/ /*#161616cc; */ /*#99000155;*/ /*#ce090355; */ /*#e9e9e9a6;*/
    --pagedialog-sider-link-text-color: #eee;
    --pagedialog-sider-link-border-color: #555;
    --pagedialog-border-color: #333;
    --pagedialog-box-shadow: 4px 6px 10px #111;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.dialog {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  box-shadow: var(--pagedialog-box-shadow); /*4px 6px 10px #111;*/
  border-radius: 6px;
  border: 1px solid var(--pagedialog-border-color);
  margin: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

.dialog[data-size="fs"] {
  justify-content: start !important;
  align-items: center;
  width: 100%;
  margin: 0 !important;
  border-radius: 0 !important;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--pagedialog-header-text-color);
  background-color: var(--pagedialog-header-background-color);
  padding: 12px;
  border-bottom: 1px solid var(--pagedialog-border-color);
  width: 100%;
}
.header p {
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 10pt;
  font-weight: 300;
}
.header small {
  font-size: 8pt;
  text-align: center;
}
.dialog[data-align="center"] .header {
  align-items: center !important;
  text-align: center !important;
}
.dialog[data-align="left"] .header {
  align-items: start !important;
  text-align: left !important;
}
.dialog[data-align="right"] .header {
  align-items: end !important;
  text-align: right !important;
}

.body {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.side-panel {
  flex-grow: var(--pagedialog-sider-flex-grow);
  flex-shrink: var(--pagedialog-sider-flex-shrink);
  flex-basis: var(--pagedialog-sider-flex-basis);
  order: 1;
  text-align: left;
  color: var(--pagedialog-sider-text-color);
  background-color: var(--pagedialog-sider-background-color);
  border-top: 0;
  border-right: 1px solid var(--pagedialog-border-color);
  padding: 24px;
  min-width: 200px;
  min-height: 260px;
}

.side-panel .side-panel-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  font-size: 9pt;
  font-weight: 400;
}

.side-panel .side-panel-body h1 {
  color: var(--pagedialog-sider-text-bright-color);
  font-size: 20pt;
  font-weight: 700;
}

.side-panel .side-panel-body h2 {
  color: var(--pagedialog-sider-text-bright-color);
  font-size: 18pt;
  font-weight: 700;
}

.side-panel .side-panel-body h3 {
  color: var(--pagedialog-sider-text-bright-color);
  font-size: 15pt;
  font-weight: 700;
}

.side-panel .side-panel-body h4 {
  color: var(--pagedialog-sider-text-bright-color);
  font-size: 13pt;
  font-weight: 700;
}

.side-panel .side-panel-body h5 {
  color: var(--pagedialog-sider-text-bright-color);
  font-size: 12pt;
  font-weight: 700;
}

.side-panel :global(.text-link) {
  color: var(--pagedialog-sider-link-text-color) !important;
  text-decoration-color: var(--pagedialog-sider-link-border-color) !important;
}

.main-panel {
  display: flex;
  flex: 1 1 auto;
  order: 2;
  flex-direction: column;
  color: var(--pagedialog-text-color);
  background-color: var(--pagedialog-background-color);
  padding: 24px;
}

.dialog[data-align="center"] .main-panel {
  align-items: center;
  text-align: center;
}

.dialog[data-align="left"] .main-panel {
  align-items: start;
  text-align: left;
}

.dialog[data-align="right"] .main-panel {
  align-items: end;
  text-align: right;
}

.main-panel h1 {
  color: var(--pagedialog-text-bright-color);
  font-size: 20pt;
  font-weight: 700;
}

.main-panel h2 {
  color: var(--pagedialog-text-bright-color);
  font-size: 16pt;
  font-weight: 400 !important;;
  margin-top: 0.5rem !important;
}

.main-panel h3 {
  color: var(--pagedialog-text-bright-color);
  font-size: 16pt;
  font-weight: 700;
}

.main-panel h4 {
  color: var(--pagedialog-text-bright-color);
  font-size: 14pt;
  font-weight: 700;
}

.main-panel :global(.text-link) {
  color: var(--pagedialog-link-text-color) !important;
  text-decoration-color: var(--pagedialog-link-border-color) !important;
}

.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 8pt;
  color: var(--pagedialog-footer-text-color);
  background-color: var(--pagedialog-footer-background-color);
  border-top: 1px solid var(--pagedialog-border-color);
  padding: 8px 10px 6px 10px;
  width: 100%;
}

.footer :global(.text-link) {
  color: var(--pagedialog-footer-link-text-color) !important;
  text-decoration-color: var(--pagedialog-footer-link-border-color) !important;
}

@media screen and (max-width: 599px) {
  .body {
    flex-direction: column;
  }
  .side-panel {
    text-align: center;
    border-bottom: 1px solid var(--pagedialog-border-color);
    border-right: 0;
  }

  .responsive.header {
    display: flex !important;
  }
  .responsive.side-panel {
    display: none !important;
  }
}

@media screen and (min-width: 600px) {
  .responsive.header {
    display: none !important;
  }
  .responsive.side-panel {
    display: flex !important;
  }
}

