.featured-image-container {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
}

.input-container {
  flex: 1 1 0%;
}

.image-container {
  flex: 1 1 0%;
  width: 500px;
  height: auto;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}