.whatsnew-container {
  font-size: 12pt;
  color: #aaa;
  background-color: #111;
  padding: 18px;
  /*background: linear-gradient(to bottom right, rgba(0, 0, 0, 1) 20%, rgb(183 6 0 / 25%));*/
}

.whatsnew-hero {
  position: relative;
  color: #ddd;
}

.whatsnew-hero > * {
  position: relative; /* Ensure text and images remain fully opaque */
  z-index: 1;
}

.whatsnew-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(183 6 0 / 10%) url(https://sassets.solv.technology/brand/solv-hero-globe-mono-white-c.png) center center / cover no-repeat;
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 1) 20%, rgb(183 6 0 / 25%)), url(https://sassets.solv.technology/brand/solv-hero-globe-mono-white-c.png) center center / cover no-repeat;
  z-index: 0;
  pointer-events: none;
}

.whatsnew-hero h1 {
  font-size: 28pt;
  font-weight: 700;
  color: #fff;
  padding: 0;
  margin: 0;
}

.whatsnew-hero h2 {
  font-size: 15pt;
  font-weight: 700;
  color: #fff;
  padding: 0;
  margin: 0;
}

.whatsnew-hero small {
  font-size: 10pt;
  font-weight: 400;
  color: #999;
  padding: 0;
  margin: 0;
}

.whatsnew-container .header {

}

.whatsnew-container .header h1 {
  font-size: 28pt;
  font-weight: 700;
  color: #fff;
  padding: 0;
  margin: 0;
}

.whatsnew-container .header p {
  font-size: 12pt;
  font-weight: 400;
  color: #ccc;
  padding: 0;
  padding-top: 1em;
  margin: 0;
}

.whatsnew-container .header small {
  font-size: 10pt;
  font-weight: 400;
  color: #888;
}

.whatsnew-container .title {
  font-size: 24pt;
  color: #fff;
  /*padding: 8px;*/
}

.whatsnew-container .title h1 {
  font-size: 24pt;
  color: #fff;
  padding: 0;
}

.whatsnew-container .content {
  font-size: 12pt;
  color: #aaa;
}

.whatsnew-container .content h1, h2, h3, h4, h5, h6 {
  font-size: 16pt;
  color: #ddd;
}

ul.features-toc {
  padding-left: 14px;
  margin-bottom: 0;
}

ul.features-toc li {
  padding: 2px 2px 2px 4px;
}

ul.features-toc li::marker {
  color: #555;
}