.search_is_focused {
  box-shadow: 0 0 0 1px var(--form-control-active-border-color) !important;
  border-radius: 5px;
}

.search_icon {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: 1px solid var(--form-control-border-color);
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--form-control-background-color) !important;
  border-right: 0 !important;
  padding-right: 6px !important;
}

.search_button {
  color: var(--button-text-color);
  height: 20px;
  padding: 0;
  margin-left: -2px;
  margin-top: -3px;
  border: 1px solid var(--form-control-border-color);
}

.search_button:hover {
  color: var(--button-text-color);
  background-color: var(--form-control-selected-background-color) !important;
}

.search_button:focus {
  color: var(--button-text-color);
  background-color: var(--form-control-selected-background-color) !important;
}

.search_text {
  /*border-left: 0 !important;*/
  /*padding-left: 0 !important;*/
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.search_clear {
  color: var(--button-text-color);
  position: relative;
  z-index: 2;
  background-color: var(--form-control-background-color) !important;
  border: 1px solid var(--form-control-border-color);
  border-left: 0 !important;
}

.search_clear:hover {
  color: var(--button-text-color);
  background-color: var(--form-control-selected-background-color) !important;
}

.search_clear:focus {
  color: var(--button-text-color);
  background-color: var(--form-control-selected-background-color) !important;
}