.modal-md {
  max-width: 650px;
}

.modal-content {
  color: var(--form-text-color);
  background-color: var(--form-background-color) !important; /*var(--form-background-color); var(--form-background-color) !important; */
  padding: 0 30px !important;
}

.modal-header {
  display: flex;
  flex-direction: column;
  background-color: var(--form-background-color) !important; /*var(--form-background-color);*/
  border-bottom: 1px solid var(--form-divider-color) !important;
  padding: 20px 0 15px 0 !important;
  /*margin-bottom: 8px;*/
}

.modal-header .close {
  color: var(--button-text-color);
  outline: 0 !important;
}

.modal-header .close:focus-visible {
  outline: 0 !important;
}

.modal-title-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.modal-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.modal-title h1 {
  font-size: 15pt;
  font-weight: 500;
  margin: 0;
}

.modal-title h2 {
  font-size: 15pt;
  font-weight: 500;
  margin: 0;
}

.modal-title h3 {
  font-size: 14pt;
  font-weight: 400;
  margin: 0;
}

.modal-title h4 {
  font-size: 14pt;
  font-weight: 400;
  margin: 0;
}

.modal-title small {
  display: block;
  color: var(--form-control-small-text-color);
}

.modal-title p {
  margin: 0;
}

.modal-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modal-footer {
  display: flex !important;
  justify-content: space-between;
  flex-wrap: inherit;
  align-items: center;
  background-color: rgb(var(--form-background-color-rgb), 1) !important; /*var(--form-background-color);*/
  border-top: 1px solid var(--form-divider-color) !important;
  padding: 15px 0 15px 0 !important;
}

.modal-body {
  background-color: rgb(var(--form-background-color-rgb), 1) !important; /*var(--form-background-color);*/
  padding: 20px 0 !important;
}

.modal-body small {
  color: var(--form-caption-color) !important;
}

.modal.responsive {
  padding: 0 !important; /* override inline padding-right added from js */
}
.modal.responsive .modal-dialog  {
  width: 90%;
  max-width: none;
  height: 90%;
  margin: auto;
}
.modal.responsive .modal-content  {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal.responsive .modal-body  {
  overflow-y: auto;
}

#inp_creativeBorderColor {
  background-color: transparent;
  padding: 0;
  width: 150px;
}

.modal-body .form-card {
  border: 0 !important;
  margin: 0 !important;
  padding-bottom: 0 !important;
}
