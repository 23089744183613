.solv-skin {
  /*
  --main-background: linear-gradient(to bottom right, rgba(0, 0, 0, 1), rgba(200,9, 3, 0.2)), url(https://sassets.solv.technology/brand/solv-hero-globe-mono-white-c.png) center center/cover no-repeat;
  --main-background: linear-gradient(to bottom right, rgba(0, 0, 0, 1) 30%, rgba(200,9, 3, 0.5)) , url(https://sassets.solv.technology/brand/solv-hero-globe-mono-white-c.png) center center/cover no-repeat;
   */
  --main-background: linear-gradient(to bottom right, rgba(0, 0, 0, 1) 20%, rgb(183 6 0 / 25%)), url(https://sassets.solv.technology/brand/solv-hero-globe-mono-white-c.png) center center / cover no-repeat;
}

@media (prefers-color-scheme: dark) {
  .solv-skin {
  }
}
