.container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 20px;
}

ul.plain-list {
    padding: 0 0 0 0px;
    margin: 0;
    list-style-type: none;
}

ul.bullet-list {
    padding: 0 0 0 16px;
    margin: 0;
    list-style-type: disc;
}

ul.plain-list li:not(:first-child),
ul.bullet-list li:not(:first-child) {
    margin-top: 12px
}

ul.plain-list li::marker,
ul.bullet-list li::marker{
    color: var(--form-divider-text-color);
}

#toggleCheckbox:checked + .hidden-label {
    display: none;
}

@media screen and (max-width: 600px) {
    .container {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        flex-direction: column;
    }
}



