.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 20px;
  /*justify-content: center;*/
}

.widgets_container {
  grid-column: span 2;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

.quick_access_container {
}

.account_info_container {
  grid-row: span 2;
}

.learn_container {
}

.invitation_container {
  grid-column: span 2;
}

.recent_container {
  grid-column: span 2;
}

.container .quick_access_container + .account_info_container {
  grid-template-columns: 1fr 1fr;
}

ul.plain-list {
  padding: 0 0 0 0px;
  margin: 0;
  list-style-type: none;
}

ul.bullet-list {
  padding: 0 0 0 16px;
  margin: 0;
  list-style-type: disc;
}

ul.plain-list li:not(:first-child),
ul.bullet-list li:not(:first-child) {
  margin-top: 12px
}

ul.plain-list li::marker,
ul.bullet-list li::marker{
  color: var(--form-divider-text-color);
}

@media screen and (max-width: 600px) {
  .container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: column;
  }
}



