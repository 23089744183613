.table {
  color: var(--text-color) !important;
  background-color: var(--table-background-color) !important;
  margin-bottom: 0 !important;
  width: 100%;
}

.table td, .table th {
  padding: 0.75rem;
}

.table thead {
  color:var(--text-color) !important;
}

.table thead th {
  font-weight: var(--table-header-text-weight);
  color: var(--table-header-text-color) !important; /* #fff; */
  background-color: var(--table-header-background-color) !important; /*#303030;*/
  vertical-align: top;
  white-space: nowrap;
}

.table tfoot {
  color:var(--text-color) !important;
}

.table tfoot th {
  font-weight: var(--table-header-text-weight);
  color: var(--table-header-text-color) !important; /* #fff; */
  background-color: var(--table-header-background-color) !important; /*#303030;*/
}

.table th, .table td {
  border-top: 1px solid var(--table-border-color) !important;
}

.table th.col-clickable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.table th.col-clickable:hover {
  color: var(--table-header-hilite-text-color) !important;
  background-color: var(--table-header-hilite-background-color) !important;
  cursor: pointer;
}

.table tbody tr {
  border-top: 0 solid transparent !important;
}

.table.table-clickable {
  cursor: pointer;
}

.table.table-clickable tbody tr:hover td {
  color: var(--table-hilite-text-color) !important;
  background-color: var(--table-hilite-background-color) !important;
}

.table a {
  /*color: var(--form-link-text-color);*/
  /*text-decoration: none;*/
  /*border-bottom: 1px solid var(--form-link-border-color);*/
}

.table a:hover {
  /*color: var(--form-link-hilite-text-color);*/
  /*text-decoration: none;*/
  /*border-bottom: 1px solid var(--form-link-hilite-border-color);*/
}

.table-bordered {
  border: 1px solid var(--table-border-color) !important;
}

.table-bordered th, .table-bordered td {
  border: 1px solid var(--table-border-color) !important;
}

.table-striped tbody tr:nth-of-type(odd){
  background-color: var(--table-row-odd-background-color) !important; /*#303030*/
}

.table-hover th.col-clickable:hover {
  background-color: var(--table-header-hilite-background-color) !important;
  cursor: pointer;
}

.table-selectable tbody tr.selected {
  color: var(--table-row-selected-text-color) !important;
  background-color: var(--table-row-selected-background-color) !important;
}

.sortable-column {
  cursor: pointer;
  margin-bottom: 0;
  width: 100%;
}

.align-right {
  text-align: right !important;
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.col-width-sm {
  max-width: 100px;
  overflow: hidden;
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap*/
  vertical-align: top;
}

.col-width-md {
  max-width: 200px;
  overflow: hidden;
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap*/
  vertical-align: top;
}

.col-width-lg {
  max-width: 300px;
  overflow: hidden;
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap*/
  vertical-align: top;
}

.col-width-lx {
  max-width: 400px;
  overflow: hidden;
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap*/
  vertical-align: top;
}
